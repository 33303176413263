export default {
  header: {
    vi: 'Việt Nam',
    en: 'English',
    solution: 'Giải pháp',
    tech_gold: 'Đồng vàng công nghệ',
    mobile_app: 'Ứng dụng di động',
    happy_stone: 'Trang sức Happy Stone',
    gift: 'Quà tặng doanh nghiệp',
    picture: 'Tranh mạ vàng',
    statue: 'Tượng phong thuỷ',
    about: 'Về chúng tôi',
    intro: 'Giới thiệu HanaGold',
    partner_a: 'Đối tác doanh nghiệp',
    cer: 'Giấy phép -  Chứng nhận',
    brand: 'HNG Branding',
    course: 'Khóa học đầu tư vàng',
    partner_b: 'Đối tác',
    affiliate: 'Đại sứ kinh doanh',
    franchise: 'Nhượng quyền trực tuyến',
    market: 'Thị trường',
    gold_price: 'Giá vàng',
    rate: 'Tỷ giá ngoại tệ',
    news: 'Tin tức',
    gold_market: 'Thị trường vàng',
    sale: 'Chương trình ưu đãi',
    mess: 'Bản tin',
    noti: 'Thông báo',
    knowledge: 'Kiến thức kinh nghiệm',
    ambassador: 'Đại sứ',
    support: 'Hỗ trợ',
    instructions: 'Hướng dẫn sử dụng',
    tele: 'Liên hệ CSKH',
    thi_truong_vang: 'Thị trường vàng',
    chuong_trinh_uu_dai: 'Chương trình ưu đãi',
    ban_tin: 'Bản tin',
    thong_bao: 'Thông báo',
    kien_thuc_dau_tu: 'Kiến thức đầu tư',
    dai_su: 'Đại sứ',
    gia_vang: 'Giá vàng',
    ty_gia_ngoai_te: 'Tỷ giá ngoại tệ',
    huong_dan_su_dung: 'Hướng dẫn sử dụng',
    technology_gold: 'Đồng vàng công nghệ',
    trang_suc_happy_stone: 'Trang sức Happy Stone',
    tranh_ma_vang: 'Trang mạ vàng',
    tuong_phong_thuy: 'Tượng phong thủy',
    trang_suc: 'Trang sức',
    dong_vang: 'Đồng vàng',
    gold_tap_tap: 'Gold Tap Tap',
    project: 'Dự án',
    gold_atm: 'Gold ATM',

    sign: 'Đăng ký',
    log: 'Đăng nhập',

    out: 'ĐĂNG XUẤT',
    mana: 'Quản Lý Vàng',
    setting: 'Cài Đặt Chung',
    se: 'Bảo Mật',
    text_m_h: 'Tải ứng dụng HanaGold để tối ưu trải nghiệm',
  },

  footer: {
    intro: 'Giới thiệu HanaGold',
    store: 'Hệ thống cửa hàng',
    product: 'Sản phẩm',
    news: 'Tin tức',
    introduce: 'HƯỚNG DẪN',
    introduce_a: 'Hướng dẫn mua hàng',
    introduce_b: 'Hướng dẫn thanh toán',
    introduce_c: 'Hướng dẫn bảo quản trang sức',
    introduce_d: 'Hướng dẫn đo size trang sức',
    introduce_e: 'Hướng dẫn giao nhận hàng',
    policy: 'ĐIỀU KHOẢN CHÍNH SÁCH',
    policy_a: 'Kiểm tra đồng vàng',
    policy_b: 'Công bố tiêu chuẩn cơ sở',
    policy_c: 'Quy định bảo hành',
    policy_d: 'Chính sách bảo mật',
    policy_e: 'Điều khoản dịch vụ',
    contact: 'LIÊN HỆ',
    name: 'Công ty CP Vàng bạc đá quý HanaGold',
    add:
      'Lô SI.26 - Tầng trệt - Block Mercury - Q7 Saigon Riverside Complex - 04 Đường Đào Trí, Phường Phú Thuận, Quận 7, Thành phố Hồ Chí Minh, Việt Nam',
    tax:
      '0316531254 • Sở Kế hoạch và Đầu tư TP Hồ Chí Minh cấp ngày 09/10/2020',
    touch: 'KẾT NỐI VỚI CHÚNG TÔI',
    community: 'CỘNG ĐỒNG HANAGOLD',
    community_a: 'Cập nhật những thông tin mới nhất',
    join: 'Tham gia',
  },

  home: {
    h_app: 'Ứng Dụng HanaGold',
    h_app_d: 'Cùng nhau tích lũy vàng',

    video: 'Xem thêm về HanaGold',
    partner: 'Đối Tác Đồng Hành',
    trans: 'Quy Đổi Giá Vàng',
    trans_a: 'Số vàng cần mua',
    trans_b: 'CHỈ',
    trans_c: 'Số tiền thanh toán',
    trans_d: 'VND',
    trans_e: 'Xem thêm giá vàng',

    so: 'Giải Pháp Kinh Doanh',
    so_a: ` Công ty cổ phần vàng bạc đá quý HanaGold thành lập từ năm
    2020, là doanh nghiệp tiên phong đổi mới sáng tạo, ứng dụng
    công nghệ 4.0 trong lĩnh vực vàng bạc đá quý.
    <br />
    <br /> HanaGold đã thành công khi mang đến trải nghiệm mua
    vàng không cần xếp hàng chỉ từ 100.000đ. Tích lũy an toàn,
    tiện lợi và nhanh chóng ở bất kỳ đâu hay bất kỳ thời gian
    nào tại ứng dụng HanaGold.`,

    so_b: `Công ty cổ phần vàng bạc đá quý HanaGold thành lập từ năm
    2020, là doanh nghiệp tiên phong đổi <br /> mới sáng tạo,
    ứng dụng công nghệ 4.0 trong lĩnh vực vàng bạc đá quý.
    HanaGold đã thành công khi mang đến trải nghiệm <br /> mua
    vàng không cần xếp hàng chỉ từ 100.000đ. Tích lũy an toàn,
    tiện lợi và nhanh chóng ở bất kỳ đâu hay bất kỳ thời <br />
    gian nào tại ứng dụng HanaGold.`,

    so_t_a: 'Ứng dụng HanaGold',
    so_d_a: ` Giải pháp tích lũy vàng từ <span>100.000 VND</span>.
    Khách hàng có thể quy đổi thành đồng vàng vật lý Kim
    Khổng Tước 99.99 khi tích lũy tối thiểu 01 CHỈ vàng.`,

    so_t_b: 'Đồng vàng Công nghệ',
    so_d_b: ` <span>Đồng vàng Kim Khổng Tước 99.99</span> tích hợp
    công nghệ NFC với khả năng định danh tài sản, chống
    vàng giả vàng kém chất lượng trôi nổi trên thị trường.
  </p>`,

    so_t_c: 'Đại sứ Kinh doanh',
    so_d_c: ` Những cá nhân mong muốn <span>gia tăng thu nhập</span>
    với chính sách hoa hồng khi chia sẻ những giá trị sản
    phẩm, dịch vụ của HanaGold đến với cộng đồng.`,

    so_t_d: 'Nhượng quyền Trực Tuyến',
    so_d_d: ` Mô hình nhượng quyền kinh doanh thương hiệu HanaGold
    trên nền tảng trực tuyến với số vốn thấp chỉ từ
    <span>30.000.000 VND</span>.`,

    achie: 'Thành Tựu Của HanaGold',
    achie_des:
      'Trải qua hơn 3 năm phát triển, chúng tôi đã có những dấu mốc đáng tự hào, là nguồn động lực để đội ngũ HanaGold không ngừng nâng cao trải nghiệm khách hàng',

    achie_a: 'Khách hàng tin dùng ứng dụng HanaGold',
    achie_b:
      'Vốn đầu tư từ Quỹ đầu tư FUNDGO, Heralding Capital và Go Global Franchise Fund',
    achie_c: 'Lượt tải ứng dụng HanaGold tại App Store/Google Play',
    achie_d: 'Chi nhánh bao gồm TP.HCM, Hà Nội, Đà Nẵng, Cần Thơ,...',
    achie_e: 'Đối tác, hiệp hội, tổ chức đồng hành phát triển',
    achie_f: 'Đại sứ kinh doanh hành trình thịnh vượng cùng HanaGold',

    about: 'Báo Chí Nói Gì Về HanaGold?',

    se: 'An Toàn Bảo Mật',
    se_h_a: 'Bảo chứng thanh toán',
    se_h_b: 'Bảo mật đa tầng',
    se_h_c: 'Hạ tầng hệ thống',

    se_a: `Liên kết với 9 ngân hàng và cổng thanh toán MoMo, EPay.`,
    se_b: ` Nạp/rút tiền từ ngân hàng vào ứng dụng hoặc từ ứng dụng <br /> về ngân hàng liên kết mọi lúc, mọi nơi.`,
    se_c: ` Thông tin Khách hàng được thu thập nhằm mục
    đích cung cấp <br /> dịch vụ và quản lý cơ sở
    dữ liệu về Người Tiêu Dùng và kịp thời <br />
    xử lý các tình huống phát sinh (nếu có).`,
    se_d: `Cơ chế bảo mật kép gồm mật khẩu thanh toán, mã
    PIN xác thực <br /> và OTP, ứng dụng công nghệ
    bảo mật sinh trắc học trong hoạt <br /> động
    cung ứng dịch vụ như xác thực vân tay, FaceID.`,
    se_e: `Hệ thống nhận diện và phát hiện giao dịch bất
    thường.`,
    se_f: `Hệ thống chăm sóc khách hàng 24/7 đáp ứng như
    mọi nhu <br />
    cầu thắc mắc, khiếu nại, hỗ trợ... trong mọi
    trường hợp.`,
    se_g: ` Toàn bộ thông tin của bạn được bảo mật bởi hạ
    tầng hệ thống <br /> Đối Tác Thứ 3 là AWS và
    Google Cloud.`,
    se_h: ` Thông tin cá nhân của Người dùng được Ban quản
    trị cam kết bảo mật <br /> tuyệt đối theo
    chính sách bảo mật thông tin cá nhân được đăng
    tải trên <br /> Website
    <a href="https://hanagold.vn">
      https://hanagold.vn
    </a>
    .`,
    system: 'Hệ Thống Cửa Hàng',
    read_more: 'Xem thêm',
    sign_now: 'Đăng ký miễn phí',
    news_hng: 'Tin tức HanaGold',
  },

  tech_gold: {
    slider_so: 'Giải Pháp Định Danh Đồng Vàng',
    slider_title: `  <h2 class="kfsj2jad sub-title"> Kim Khổng Tước 24K <br /> Tích Hợp Công Nghệ NFC </h2>`,
    buy_now: 'Mua ngay',
    first: 'Tiên Phong Chuyển Đổi Số',
    gold_a: 'Ngành Kinh Doanh Vàng',
    gold_a_des:
      'HanaGold đã nghiên cứu và phát triển thành công đồng vàng Kim Khổng Tước 24k tích hợp công nghệ',
    gold_b_des:
      'với khả năng định danh tài sản, truy xuất nguồn gốc và chống vàng giả trôi nổi trên thị trường.',
    quality: '*Giấy công bố tiêu chuẩn',

    meaning: `<h2 class='sub-title'>Ý Nghĩa</h2> <p> Đồng Vàng Kim Khổng Tước được HanaGold lên ý tưởng <br /> thiết kế với biểu tượng chim Khổng Tước tượng trưng cho <br /> <span>Hạnh Phúc – Cao Quý – May Mắn – Bình An</span> <br /> cho khách hàng sở hữu. </p>`,
    partner: ` <h2 class='sub-title'>Đối Tác Chiến Lược</h2>
    <p>
      <span style={{ fontStyle: 'normal' }}>
        Các chuyên gia kim hoàn của VIETAGOLD
      </span>
      &nbsp;đã sử dụng công nghệ sản xuất hiện đại bậc nhất để biến
      những <br />ý tưởng thành một sản phẩm đồng vàng 24k đẹp mắt,
      <br />
      tinh tế pha lẫn giữa những nét đẹp truyền thống và <br /> công
      nghệ đảm bảo truyền tải ý nghĩa, mong đợi mà <br /> khách hàng
      mong muốn.
    </p>`,
    embed_tech: 'Hệ Thống Công Nghệ Tích Hợp',
    gold_phoneix_t: 'Đồng Vàng Kim Khổng Tước',
    gold_phoneix_des_a: ' Định lượng: <span>1 CHỈ</span>',
    gold_phoneix_des_b: ' Trọng lượng: <span>3.75grs</span>',
    gold_phoneix_des_c: ' Chất lượng: <span>24K</span>',
    gold_phoneix_des_d: ' Kích thước: <span>20mm</span>',
    benefit: 'Lợi ích sở hữu',
    benefit_a: `<p>
    Giúp định danh chủ sở hữu, tài sản vàng chính chủ <br /> và
    bảo vệ được tài sản khi xảy ra tranh chấp.
  </p>`,

    benefit_b: `<p>
    Giúp truy xuất nguồn gốc sản phẩm, chất lượng vàng, <br />
    thông tin sản phẩm chi tiết, minh bạch và truy vết lịch sử
    <br /> giao dịch.
  </p>`,

    benefit_c: ` <p>
    Chống vàng giả, vàng kém chất lượng, mạo danh <br />
    thương hiệu tiệm vàng.
  </p>`,

    benefit_d: `<p>
    Chứng nhận vàng ứng dụng công nghệ NFT độc bản <br /> chỉ
    duy nhất 1 người sở hữu, chuyển nhượng tặng <br /> người
    thân, bạn bè ngay trên ứng dụng HanaGold.
  </p>`,

    benefit_e: `<p>
    Giúp các tiệm vàng quản lý hàng hoá, bảo vệ <br /> khách
    hàng của mình, và tránh mua phải vàng lậu,
    <br /> vàng không rõ nguồn gốc.
  </p>`,

    benefit_mb_a:
      'Giúp định danh chủ sở hữu, tài sản vàng chính chủ và bảo vệ được tài sản khi xảy ra tranh chấp',
    benefit_mb_b:
      'Giúp truy xuất nguồn gốc sản phẩm, chất lượng vàng, thông tin sản phẩm chi tiết, minh bạch và truy vết lịch sử giao dịch',
    benefit_mb_c:
      'Chống vàng giả, vàng kém chất lượng, mạo danh thương hiệu tiệm vàng.',
    benefit_mb_d:
      ' Chứng nhận vàng ứng dụng công nghệ NFT độc bản chỉ duy nhất 1 người sở hữu, khi cần có thể chuyển nhượng để cho tặng người thân, bạn bè ngay trên HanaGold App',
    benefit_mb_e:
      ' Giúp các tiệm vàng quản lý hàng hoá của tiệm mình, bảo vệ khách hàng của mình, và tránh mua phải vàng lậu, vàng không rõ nguồn gốc.',

    faq: 'Câu Hỏi Thường Gặp',
    faq_sub: 'Dành cho khách hàng HanaGold',
    list: 'Danh mục câu hỏi',

    products: 'Sản phẩm',
    tech: 'Công nghệ',
    policy: 'Chính sách',
    guide: 'Hướng dẫn',

    products_a: 'Đồng vàng công nghệ HanaGold là sản phẩm gì?',
    products_b: 'Đối tác chiến lược nào tham gia sản xuất sản phẩm?',
    products_c:
      'Điểm khác biệt giữa đồng vàng công nghệ HanaGold và đồng vàng truyền thống',
    products_d:
      'Đồng vàng công nghệ có giấy chứng nhận dành cho khách hàng sở hữu không?',
    products_e: 'Kết quả thẩm định tiêu chuẩn đồng vàng như thế nào?',

    products_des_a: `
    <p>
      Đồng vàng công nghệ HanaGold 24k với tên gọi
      Kim Khổng Tước được tích hợp công nghệ NFC
      giúp định danh tài sản vàng và truy xuất nguồn
      gốc, xuất xứ.
      <a
        href="https://hanagold.vn/news/hanagold-cong-bo-giai-phap-dinh-danh-dong-vang-kim-khong-tuoc"
        target="_blank"
      >
        Xem thêm
      </a>
    </p>
    <h6>Thông tin đồng vàng:</h6>
    <p>
      Nguyên liệu: 24k (999.9) <br /> Định lượng:
      99,99%
      <br /> Trọng lượng: 1 chỉ - 3,75 grs <br />
      Kích thước: 20 mm <br /> Chip: NFC
    </p>
`,
    products_des_b: `
    <p>
    Đồng vàng công nghệ HanaGold được sản xuất gia
    công bởi Công ty vàng bạc đá quý Việt Á
    (VIETAGOLD)
    <a
      href="https://hanagold.vn/news/hanagold-cong-bo-giai-phap-dinh-danh-dong-vang-kim-khong-tuoc"
      target="_blank"
    >
      Xem thêm
    </a>
  </p>
`,

    products_des_c: `
    <p>
    Được tích hợp công nghệ NFC trên mỗi đồng vàng,
    đảm bảo an toàn cho chủ sở hữu khi tích lũy tại
    HanaGold
  </p>
`,

    products_des_d: `
    <p>
    Có. Mỗi đồng vàng khách hàng kích hoạt sẽ sở hữu
    giấy chứng nhận định dạng PDF và được HanaGold
    gửi qua email khách hàng.
  </p>
`,
    products_des_e: `<p>
    Đồng vàng công nghệ HanaGold 24k chính thức được
    cấp công bố tiêu chuẩn áp dụng theo TCCS
    01/2023/HNG và kết quả thử nghiệm từ Phòng Thử
    nghiệm Cơ khí - Vật liệu xây dựng.
    <a
      href="https://hanagold.vn/news/hanagold-cong-bo-tieu-chuan-dong-vang"
      target="_blank"
    
    >
      Xem thêm
    </a>
  </p>`,

    tech_a: 'Hệ thống công nghệ nào được tích hợp và sử dụng?',
    tech_b: 'Công nghệ NFC là gì?',
    tech_c: 'Công nghệ NFC được sử dụng để định danh tài sản vàng thế nào?',
    tech_d: 'Công nghệ Fintech là gì và hoạt động thế nào?',
    tech_e: 'Công nghệ Blockchain là gì?',
    tech_f: 'Công nghệ NFT là gì?',

    tech_des_a: `
    <p>
                                Với sự phát triển vượt bậc của công nghệ 4.0 tại
                                Việt Nam và thế giới trong nhiều năm qua,
                                HanaGold đã thành công trong việc tiếp cận và
                                ứng dụng công nghệ Fintech (công nghệ tài
                                chính), NFC và Blockchain (Giấy chứng nhận NFT)
                                vào sản phẩm đồng vàng Kim Khổng Tước HanaGold
                                <a
                                  href="https://hanagold.vn/news/cong-nghe-tich-hop-trong-san-pham-cua-hanagold"
                                  target="_blank"
                                
                                >
                                  Xem thêm
                                </a>
                              </p>`,

    tech_des_b: `<p>
    NFC là từ viết tắt của Near-Field
    Communications, có nghĩa là giao tiếp trường
    gần. Đây là một công nghệ không dây cho phép
    truyền thông tin và dữ liệu giữa các thiết bị ở
    khoảng cách gần (từ vài centimet đến khoảng
    10cm) thông qua sóng radio. Công nghệ này sử
    dụng cảm ứng từ trường để thực hiện kết nối giữa
    các thiết bị khi có sự tiếp xúc trực tiếp hoặc
    để gần nhau.
  </p>`,
    tech_des_c: ` <p>
    - Mỗi đồng vàng Kim Khổng Tước 24k sẽ được tích
    hợp 01 chip NFC và được liên kết thông qua ứng
    dụng HanaGold.
    <br /> <br />- NFC cho phép truy xuất nguồn gốc
    sản phẩm, chất lượng vàng, thông tin sản phẩm
    chi tiết, truy vấn lịch sử giao dịch nhanh
    chóng, chính xác.
    <br /> <br />- Khi cần truy xuất dữ liệu, khách
    hàng chỉ cần quét đồng vàng trên điện thoại
    thông qua ứng dụng HanaGold để kiểm tra thông
    tin đồng vàng.
  </p>`,

    tech_des_d: `<p>
    Fintech là viết tắt của Financial Technology, là
    sự kết hợp giữa công nghệ và tài chính. Fintech
    là việc áp dụng các công nghệ mới như trí tuệ
    nhân tạo (AI), máy học (ML), blockchain, big
    data,... để cải thiện và nâng cao chất lượng của
    các dịch vụ tài chính truyền thống.
    <br />
    <br />
    Công nghệ Fintech đã có tác động đáng kể đến
    ngành tài chính, giúp các dịch vụ tài chính trở
    nên dễ tiếp cận hơn, tiết kiệm chi phí hơn và
    hiệu quả hơn.
  </p>`,

    tech_des_e: ` <p>
    Công nghệ Blockchain là một công nghệ cơ sở dữ
    liệu phân tán, phi tập trung, có khả năng lưu
    trữ thông tin một cách an toàn và minh bạch. Dữ
    liệu trong Blockchain được lưu trữ dưới dạng các
    khối (Block), được liên kết với nhau theo một
    chuỗi (Chain). Mỗi khối chứa thông tin về khối
    trước đó, bao gồm thời gian tạo, dữ liệu được
    lưu trữ và mã băm của khối trước đó.
  </p>`,

    tech_des_f: `<p>
    NFT (Non-Fungible Token) là tài sản kỹ thuật số
    duy nhất được lưu trữ trên blockchain. NFT có
    thể đại diện cho bất kỳ thứ gì kỹ thuật số như
    hình ảnh, video, âm nhạc, trò chơi và các vật
    phẩm trong thế giới thực.
    <br />
    <br />
    NFT được tạo ra bằng cách mã hóa một tệp kỹ
    thuật số với một hợp đồng thông minh (Smart
    contract). Hợp đồng thông minh này ghi lại quyền
    sở hữu của NFT và đảm bảo chỉ có thể được sở hữu
    bởi một người tại một thời điểm.
    <a
      href="https://hanagold.vn/news/giay-chung-nhan-vang-nft"
      target="_blank"
     
    >
      Xem thêm
    </a>
  </p>`,

    policy_a: 'Chính sách thu mua vàng của HanaGold như thế nào?',
    policy_b: 'Nếu sản phẩm tôi mua bị lỗi thì được xử lý ra sao?',

    policy_des_a: ` <p>
    - Khách hàng cần mang theo sản phẩm đến 1 trong
    4 chi nhánh của HanaGold trên toàn quốc để được
    giám định theo quy định. Trường hợp đúng sản
    phẩm của HanaGold thì sẽ thu mua lại theo giá
    vàng nguyên liệu. Trường hợp sản phẩm không phải
    của HanaGold, HanaGold sẽ gửi kết quả giám định
    cho khách hàng và sẽ phải chịu chi phí kiểm định
    theo Biểu phí của HanaGold theo từng thời điểm
    cụ thể.
    <br />
    <br />
    Bước 1: HanaGold sẽ kiểm tra và truy xuất nguồn
    gốc sản phẩm bằng công nghệ NFC trên ứng dụng
    HanaGold
    <br />
    <br />
    Bước 2: Thẩm định đồng vàng, bao bì sản phẩm
    <br />
    <br />
    Bước 3: HanaGold báo lại tình trạng và giá trị
    thu hồi sản phẩm. Hai bên xác nhận giao dịch
    <br />
    <br />
    Bước 4: HanaGold giải ngân tiền thu mua vàng
  </p>`,

    policy_des_b: ` <p>
    - Sản phẩm lỗi do nhà sản xuất sẽ được bảo hành
    đổi trả theo từng đợt;
    <br />
    <br />- Trường hợp sản phẩm lỗi, hỏng do quá
    trình lưu trữ, bảo quản, sử dụng của khách hàng,
    HanaGold sẽ tính phí khi mua lại sản phẩm.
  </p>`,

    guide_a: 'Tôi có thế mua và bán đồng vàng công nghệ ở đâu, cho ai?',
    guide_b: 'Làm thế nào để quét đồng vàng công nghệ trên ứng dụng HanaGold',
    guide_c: 'Làm thế nào để tôi chuyển chủ sở hữu đồng vàng?',
    guide_d: 'Làm thế nào để tôi thông báo rằng đồng vàng đã bị thất lạc?',

    guide_des_a: `<p>
    Khách hàng có thể mua, bán trực tiếp đồng vàng
    công nghệ 24k của HanaGold tại 04 cửa hàng trên
    toàn quốc, bao gồm
    <span>TP.HCM, Hà Nội, Đà Nẵng, Cần Thơ</span>.
    Khách hàng chỉ cần đặt hàng tại ứng dụng
    HanaGold, sản phẩm sẽ được giao đến tận tay của
    khách hàng. Hoặc liên hệ trực tiếp qua Hotline
    19002161, Zalo OA
    <a
      href=" https://zalo.me/vanghanagold"
      target="_blank"
     
    >
      https://zalo.me/vanghanagold
    </a>
    để HanaGold trực tiếp tư vấn và hướng dẫn đặt
    hàng chi tiết.
  </p>`,

    guide_des_b: ` <div>
    <p>
      Đầu tiên, thiết bị di động của khách hàng cần có
      tính năng NFC
      <br />
      <br />
      Bước 1: Tại màn hình chính chọn “Danh mục”
      <br />
      <br />
      Bước 2: Kéo xuống dưới, chọn “Định danh đồng
      vàng”
      <br />
      <br />
      Bước 3: Chọn “Quét NFC”
      <br />
      <br />
      Bước 4: Đặt đồng vàng ở mặt sau điện thoại trong
      3s - 5s
      <br />
      <br />
      Lưu ý: Vị trí quét của từng điện thoại có thể
      khác nhau, bạn nên thử đặt ở mọi vị trí của mặt
      sau điện thoại nhằm đảm bảo kích hoạt NFC thành
      công.
    </p>
  </div>`,
    guide_des_c: `<p>
    Bước 1: Màn hình chi tiết đồng vàng, chọn
    "Chuyển sở hữu"
    <br />
    <br />
    Bước 2: Nhập Email/SĐT người nhận và chọn
    "Chuyển sở hữu"
    <br />
    <br />
    Bước 3: Popup xác nhận xuất hiện và click Xác
    nhận
    <br />
    <br />
    Lưu ý: Email/SĐT phải có trong hệ thống của
    HanaGold và khách hàng phải xác thực tài khoản
  </p>`,

    guide_des_d: `
    <p>
    Bước 1: Màn hình chi tiết đồng vàng, Chọn Report
    lock
    <br />
    <br />
    Bước 2: Popup xác nhận xuất hiện và click Xác
    nhận, hoàn tất quá trình khóa giao dịch đồng
    vàng này. Tính năng Chuyển nhượng sẽ không hoạt
    động trừ khi khách hàng Hủy yêu cầu.
    <br />
    <br />
    Lưu ý: Trong vòng 24h từ lúc Hủy yêu cầu tính
    năng này sẽ bị khóa đối với đồng vàng này.
  </p>`,

    community: 'Cộng đồng Tích Lũy Vàng 4.0',
    community_des: 'Dành cho khách hàng HanaGold',
    community_btn: 'Tham gia ngay',
  },

  mobile_app: {
    title: 'Ứng Dụng HanaGold',
    title_b: 'Cùng Nhau Tích Lũy Vàng',
    title_c: `  Bắt đầu đầu tư và quản lý tài sản vàng của bạn <br /> với giá
    chỉ từ 100.000 VND`,
    btn_a: 'Khám phá ngay',

    intro: 'Giới Thiệu',
    intro_des: ` Ứng dụng HanaGold giúp khách hàng mua, bán, tích lũy và quản lý
    tài sản vàng một cách dễ dàng, an toàn và hiệu quả.
    <br />
    <br />
    Khách hàng có thể tích lũy vàng bền vững với số vốn nhỏ từ
    100.000đ, cùng nhiều tính năng sinh lời mỗi ngày và rút tiền
    nhanh chóng, tiện lợi.
    <br />
    <br />
    Đặc biệt, khách hàng cũng có thể sở hữu đồng vàng công nghệ
    HanaGold tích hợp NFC chống vàng giả, truy xuất nguồn gốc sản
    phẩm và định danh khách hàng sở hữu.`,

    benefit: 'Lợi Ích Tích Lũy',

    benefit_t_a: 'Mua vàng tiện lợi 24/7',
    benefit_d_a: ` Tại ứng dụng HanaGold, bạn có thể mua, bán vàng
    <br />
    và thanh toán trực tuyến 24/7 tiện lợi, nhanh chóng, <br />
    dễ dàng, đảm bảo tránh được các rủi ro thường gặp <br />
    khi mua vàng truyền thống`,

    benefit_t_b: 'Mua vàng với số vốn nhỏ',
    benefit_d_b: ` Khách hàng có thể dễ dàng tích lũy vàng với số vốn <br />
    nhỏ chỉ từ 100.000VND, gia tăng cơ hội sở hữu vàng <br />
    cho người có thu nhập thấp.`,

    benefit_t_c: 'Chênh lệch mua vào bán ra thấp',
    benefit_d_c: `        Giá vàng HanaGold hiện nay có thể khẳng định <br />
    rất cạnh tranh với các thương hiệu lớn khác, <br />
    không những thế chênh lệch mua vào và bán ra <br />
    chỉ 50.000đ/chỉ trong khi các thương hiệu khác <br />
    rơi vào 80.000đ -120.000đ/chỉ, vì vậy khách hàng <br />
    của HanaGold có cơ hội chốt lời cao hơn và lời <br /> hơn
    khi giao dịch`,

    benefit_t_d: 'Truy xuất nguồn gốc nhanh chóng',
    benefit_d_d: ` Sản phẩm vàng HanaGold được tích hợp công nghệ <br /> NFC
    giúp khách hàng dễ dàng kiểm tra thông tin, <br />
    chất lượng vàng tiện lợi Hoá đơn chứng từ online: <br />
    Khi mua vàng tại HanaGold, hoá đơn sẽ được lưu <br />
    giữ trực tuyến, truy xuất bất kỳ lúc nào, đảm bảo an <br />
    toàn và bảo mật cao.`,

    benefit_t_e: 'Danh mục đầu tư sinh lời đa dạng',
    benefit_d_e: ` Tích lũy vàng dễ dàng với vốn đầu tư Với các tính năng
    <br />
    “Thưởng mỗi ngày”, "Cùng đầu tư” với các mốc <br /> thời
    gian đầu tư khác nhau như 30, 90, 365 ngày đem <br />
    lại cơ hội lợi nhuận cho khách hàng bền vững`,

    benefit_t_f: 'Tiết kiệm tự động',
    benefit_d_f: `Ứng dụng HanaGold có tính năng "Nuôi heo vàng” <br /> giúp
    khách hàng có thể thiết lập kế hoạch tích luỹ <br />
    vàng theo ngày, tuần, tháng mà không lo quá <br /> nhiều về
    biến động giá, chủ động quản lý tài sản <br /> và giúp đạt
    mục tiêu tài chính vững vàng`,

    benefit_t_g: 'Tin tức thị trường và giá vàng minh bạch',
    benefit_d_g: `HanaGold luôn cung cấp các thông tin biến động <br /> về giá
    vàng, tin tức thị trường vàng Việt Nam và <br /> thế giới
    giúp khách hàng luôn cập nhật kịp thời, <br /> bên cạnh đó
    giá vàng HanaGold luôn công khai <br /> minh bạch 24/7 giúp
    khách hàng ra quyết định <br />
    mua bán chính xác hơn`,

    feature: 'Tính Năng Nổi Bật',

    feature_t_a: 'Mua vàng',
    feature_d_a: 'Tích lũy vàng nhanh chóng với một thao tác, từ 100.000 VND',

    feature_t_b: 'Cùng đầu tư',
    feature_d_b:
      '  Sinh lời nhận thưởng mỗi ngày với kỳ hạn 30, 90 và 365 ngày',

    feature_t_c: 'Nuôi heo vàng',
    feature_d_c: 'Tích lũy vàng tự động phù hợp người bận rộn',

    feature_t_d: 'E-Voucher',
    feature_d_d: 'Tặng vàng cho người thân, bạn bè, gia đình',

    feature_t_e: 'Giới thiệu bạn bè',
    feature_d_e:
      ' Gia tăng thu nhâp từ 500.000 VND tại ví hoa hồng khi giới thiệu bạn bè sử dụng HanaGola',

    feature_t_f: 'Thưởng mỗi ngày',
    feature_d_f: 'Nhận thưởng mỗi ngày khi trữ 0,5 CHỈ vàng tại ví chính',

    tech: 'HanaGold Tích Hợp Công Nghệ',
    tech_a: 'BlockChain Từ OnusChain',

    save: 'HanaGold - Đầu Tư Và Tích Lũy',
    save_a: 'Vàng Công Nghệ NFC',

    feeling: 'Cảm Nhận Khách Hàng',
    feeling_a: 'Về Trải Nghiệm HanaGold',

    about: 'Về HanaGold',
    about_text: `Công ty cổ phần Vàng Bạc Đá Quý HanaGold thành lập vào năm 2020,
    là doanh nghiệp khởi nghiệp đổi mới sáng tạo trong lĩnh vực vàng
    bạc đá quý. Chúng tôi ứng dụng công nghệ 4.0 trong hoạt động
    kinh doanh vàng (tiệm vàng công nghệ 4.0).
    <br />
    <br />
    Tiềm năng trong thời đại công nghệ 4.0 cùng với nhu cầu sử dụng
    và đầu tư thị trường vàng, HanaGold nhanh chóng chớp lấy thời cơ
    phát triển ngành vàng tại Việt Nam theo mô hình cải tiến hơn so
    với truyền thống, tạo bước đột phá cho nền kinh doanh vàng tại
    Việt Nam.
    <br />
    <br />
    Nhiệm vụ của chúng tôi là đảm bảo giá trị tài sản cho bạn và đem
    lại sự an tâm khi gắn kết.`,

    btn_res: 'Đăng ký',
  },
  partners: {
    title: 'ĐỐI TÁC HANAGOLD',
  },

  certificate: {
    title: 'Giấy Phép - Chứng Nhận',

    cer_a: 'Giấy chứng nhận phần mềm HanaGold',

    cer_b: 'Giấy chứng nhận trang sức Kim Khổng Tước',

    cer_c: 'Giấy chứng nhận Kim Khổng Tước',

    cer_d: 'Giấy chứng nhận giải Ba TECHFEST 2021',

    cer_e: 'Đăng ký nhãn hiệu',
    association: 'Hội Viên - Hiệp Hội',

    association_a: 'Hội Doanh nhân Trẻ',
    association_b: 'Hội Mỹ nghệ Kim hoàn Đá quý Việt Nam',
    association_c: 'Hội viên Danh dự Hiệp hội Blockchain Việt Nam',
    association_d: 'Hội Mỹ Nghệ Kim hoàn Đá quý TP.HCM',
    association_e: 'CÂU LẠC BỘ NHƯỢNG QUYỀN',
    quality: 'Tiêu Chuẩn Đồng Vàng',
    quality_a: 'Công bố tiêu chuẩn cơ sở Đồng vàng',
    quality_b: 'Thử nghiệm Vàng',
  },

  aff: {
    btn_sign: 'Register',
    title: 'Đại sứ Kinh doanh',
    title_a: 'Thịnh vượng cùng HanaGold',
    title_b:
      'Cơ hội gia tăng tài chính và phát triển bản thân, xây dựng đội ngũ kinh doanh chuyên nghiệp',
    btn_a: 'Đăng ký ngay',

    policy_a: 'Chính sách hoa hồng',
    policy_a_des:
      '  Hoa hồng không giới hạn - Chuyển đổi khách hàng tiềm năng trên mạng xã hội',
    more: 'Tìm hiểu thêm',

    policy_b: 'Về ứng dụng HanaGold',
    policy_b_des:
      'Bắt đầu gia tăng thu nhập bằng những tính năng nổi bật trên ứng dụng HanaGold.',

    sign_a: 'Tham gia các chương trình đào tạo và phát triển chuyên sâu',
    sign_b: ' Được nhận các loại hoa hồng, các chương trình ưu đãi đặc biệt',
    sign_c:
      ' Được ưu tiên hỗ trợ khi gặp các sự cố trong quá trình sử dụng HanaGold',
    sign_d: 'Được tham dự các hoạt động quảng bá và tiếp thị sản phẩm',

    schedule: 'Lộ Trình Phát Triển',

    schedule_a: `<h3 class="t_d">Cổ đông</h3>
    <h3 class="t_mb">Cổ đông</h3>
    <p>
      Mong muốn sở hữu cổ phần và cùng tham gia phát triển cùng Ban
      Lãnh Đạo và HĐQT trong thời gian trên 03 năm.
    </p>`,

    schedule_b: `<h3 class="t_d">
    Nhượng quyền <br /> Cửa hàng
  </h3>
  <h3 class="t_mb">Nhượng quyền Cửa hàng</h3>

  <p>
    Là mô hình kinh doanh nhượng quyền cửa hàng vàng thương hiệu
    HanaGold tại vị trí địa lý.
  </p>`,

    schedule_c: `<h3 class="t_d">
    Nhượng quyền <br />
    Trực tuyến
  </h3>
  <h3 class="t_mb">Nhượng quyền Trực tuyến</h3>
  <p>
    Là mô hình nhượng quyền kinh doanh thương hiệu HanaGold trên
    nền tảng trực tuyến.
  </p>`,

    schedule_d: `<h3 class="t_d">
    Đại sứ <br /> Kinh doanh
  </h3>
  <h3 class="t_mb">Đại sứ Kinh doanh</h3>
  <p>
    Là những cá nhân mong muốn gia tăng thu nhập với chính sách
    hoa hồng khi chia sẻ những giá trị sản phẩm ,dịch vụ của
    HanaGold đến với cộng đồng.
  </p>`,

    schedule_e: `<h3 class="t_d">Người dùng</h3>
    <h3 class="t_mb">Người dùng</h3>
    <p>
      Là những khách hàng sử dụng sản phẩm, dịch vụ của HanaGold.
    </p>`,

    step: 'Các bước tham gia',

    step_a: `<h3>Đăng ký</h3>
    <p>
      Đăng ký trực tiếp tại ứng dụng
      <br />
      HanaGold
    </p>`,

    step_b: `<h3>Thẩm định</h3>
    <p>HanaGold xem xét hồ sơ đăng ký</p>`,

    step_c: ` <h3>Đào tạo</h3>
    <p>Chuyên môn & kỹ năng</p>`,

    step_d: `<h3>Nâng cấp</h3>
    <p>Trở thành Đại sứ HanaGold</p>`,

    share: 'Chia Sẻ Hành Trình',
    share_a: 'Thịnh Vượng Cùng',
    share_b: 'Đại Sứ HanaGold',

    share_text_a: ` Từ khi biết đến HanaGold Công việc kinh doanh của tôi
    thuận lợi hơn rất nhiều . Từ việc Tích lũy Vàng cho
    đến mua bán trao đồi. Ưu điểm lớn Nhất Là NFC định
    danh trên đồng Vàng Kim Khổng Tước. giúp tôi an tâm
    hơn và ngăn ngừa hàng giả.
    <br />
    <br />
    Ngoài ra việc kinh doanh cùng HanaGold giúp tôi cải
    thiện rất nhiều về Kinh tế thông qua Chương trình giới
    thiệu với các Phần thường Hoa Hồng Hấp dẫn.
    <br />
    <br />
    Chúc HanaGold Phát Triển Cường Thịnh.!`,
    share_text_b: ` Một mảng lướn chưa có định hướng. Mảng quỹ mang lại
    giá trị cộng đồng. Từ thiện. Gây quỹ từ bây giờ và
    hoạt động song song. Quỹ tới đâu hoạt động tới đó. Sẽ
    mang lại trọn vẹn ý nghĩa và giá trị.
    <br />
    <br />
    HanaGold là tiên phong. Nhà đầu tư hưởng ứng. Hoạt
    động bền bỉ. Khả năng đột phá như mình nghĩ sẽ thêm
    phần mạnh hơn.`,

    share_text_c: ` Khi trở thành Đại sứ của HanaGold, mình được học thêm
    rất nhiều kiến thức về quản lý tài chính cá nhân cũng
    như tích lũy vàng an toàn. Ngoài ra, đây cũng là cơ
    hội để gia tăng thu nhập thụ động không giới hạn khi
    giới thiệu bạn bè cùng tích lũy. Chính sách Đại sứ của
    HanaGold là công cụ để mọi người có thể xây dựng con
    đường tự do tài chính cho chính mình.`,

    share_text_p: 'Đại sứ Phát triển',

    about_title: 'Về HanaGold',
    about_text: ` Công ty cổ phần Vàng Bạc Đá Quý HanaGold thành lập vào năm 2020,
    là doanh nghiệp khởi nghiệp đổi mới sáng tạo trong lĩnh vực vàng
    bạc đá quý. Chúng tôi ứng dụng công nghệ 4.0 trong hoạt động
    kinh doanh vàng (tiệm vàng công nghệ 4.0).
    <br />
    <br />
    Tiềm năng trong thời đại công nghệ 4.0 cùng với nhu cầu sử dụng
    và đầu tư thị trường vàng, HanaGold nhanh chóng chớp lấy thời cơ
    phát triển ngành vàng tại Việt Nam theo mô hình cải tiến hơn so
    với truyền thống, tạo bước đột phá cho nền kinh doanh vàng tại
    Việt Nam.
    <br />
    <br />
    Nhiệm vụ của chúng tôi là đảm bảo giá trị tài sản cho bạn và đem
    lại sự an tâm khi gắn kết.`,

    sign: 'Đăng ký trở thành',
    sign_aff: 'Đại Sứ HanaGold',

    app: 'Ứng Dụng HanaGold',
    app_text: 'Cùng nhau tích lũy vàng',
    btn_b: 'Đăng ký ngay',
  },

  franchise: {
    fr_l_a: ` Mang đến cơ hội kinh doanh vàng cực kỳ dễ dàng, đơn giản, tiện
    lợi cùng HanaGold`,

    fr_l_b: ` Trao cơ hội khởi nghiệp kinh doanh vàng trên nền tảng hiện có
    của HanaGold`,

    fr_l_c: `Cùng HanaGold tạo nên chuỗi cửa hàng nhượng quyền vàng trực
    tuyến trên khắp Việt Nam và quốc tế`,

    copy_text: 'Sao chép',

    title: 'Nhượng Quyền Trực Tuyến',
    title_text: ` Mô hình nhượng quyền kinh doanh thương hiệu HanaGold trên <br />
    nền tảng trực tuyến. Nhà đầu tư có thể dễ dàng sở hữu 1 cửa hàng
    <br /> kinh doanh vàng trang sức với số vốn nhỏ, linh hoạt về
    thời gian và <br /> địa điểm kinh doanh.`,

    be: ` Quyền Lợi <span>Hợp Tác Kinh Doanh</span>`,

    be_a: `<h3>Kinh doanh vàng trang sức 24/7</h3>
    <p>
      Trên nền tảng trực tuyến mà không phải đầu tư <br /> chi phí
      quá lớn cho cửa hàng.
    </p>`,
    be_b: `<h3>Số vốn nhỏ</h3>
    <p>
      Tối ưu chi phí nhượng quyền và vận hành với <br /> số vốn từ
      30.000.000 VND.
    </p>`,
    be_c: `<h3>Nền tảng HanaGold</h3>
    <p>
      Được quyền sử dụng nền tảng và thương <br /> hiệu HanaGold để
      kinh doanh với chính sách <br /> hoa hồng cao nhất.
    </p>`,
    be_d: `<h3>Thời gian hòa vốn</h3>
    <p>
      Nhanh chóng và thu nhập cao khi Đối tác xây <br /> dựng và
      phát triển thành công tệp khách hàng <br /> thân thiết.
    </p>`,
    be_e: `<h3>Thông tin Đối tác</h3>
    <p>
      Được công bố và hiển thị trên website <br />
      <a>hanagold.vn</a> và được bảo chứng từ thương hiệu <br />
      HanaGold.
    </p>`,
    be_f: `<h3>Chính sách hoa hồng</h3>
    <p>
      Với mức cao nhất cùng nhiều tính năng mang đến <br /> cơ hội
      gia tăng thu nhập thụ động.
    </p>`,

    title_se: ` hạng mục <span>nhượng quyền</span>`,

    table_a_title_a: `STT`,
    table_a_title_b: `Hạng Mục`,
    table_a_title_c: `Nội dung`,

    rank_a: 'Vốn đầu tư',
    rank_b: 'Tài khoản Nhượng quyền Trực tuyến',
    rank_c: 'Hợp đồng Nhượng quyền Trực tuyến',
    rank_d: 'Giấy chứng nhận đối tác Nhượng quyền Trực tuyến',
    rank_e: ' Nền tảng công nghệ, tài liệu kinh doanh',
    rank_f: ' Combo gói hỗ trợ (hình ảnh, truyền thông, đào tạo…)',
    rank_g: '*HanaGold tặng Đối tác',

    title_sp: `gói hỗ trợ <span>300.000.000 VND</span>`,

    table_b_title_b: `Nội dung`,
    table_b_title_c: `Giá trị`,

    sp_a: 'Tài khoản VIP HanaGold',
    sp_b: 'Gói huấn luyện kỹ năng kinh doanh',
    sp_c: 'Tư vấn và quảng bá truyền thông',
    sp_d: 'Công cụ marketing',
    sp_e: 'Nền tảng công nghệ HanaGold',
    sp_f: 'Sử dụng thương hiệu HanaGold',

    title_ex: ` Bảng minh Họa <span>tài chính</span>`,

    ex_a: '  Doanh thu phát triển khách hàng',
    ex_b: '   Hoa hồng phân phối vàng (10 chỉ/Khách hàng)',
    ex_c: ' Nâng cấp VIP KH (nâng cấp VIP 3*)',
    ex_d: ' Hợp đồng cùng hợp tác (10 chỉ vàng, 10.000 HNG)',
    ex_e: `<span className="text_white_b_i">(*)</span> Thưởng doanh số
    tháng`,
    ex_f: 'Tổng thu nhập',

    text_des: `(*) Thưởng doanh số tháng:
    <span>
      Tổng doanh số khi cấp Đại sứ Kinh doanh và cấp Người dùng rút
      tiền tại ví hoa hồng
    </span>`,

    sp_title: 'Ban Cố Vấn',

    sp_des_a: `Chuyên gia Nhượng quyền Quốc Tế`,
    sp_des_b: ` Tổng Giám đốc Quỹ <br /> Go Global Franchise Fund`,
    read_more: 'Xem thêm',

    fr_step_title: 'Hành Trình Trở Thành',
    fr_step_title_a: 'Đối Tác Nhượng quyền Trực tuyến',

    fr_step_title_center: 'QUY TRÌNH ĐĂNG KÝ',
    fr_step_a: 'Đăng Ký',
    fr_step_a_des: ` Sau khi tìm hiểu và được tư vấn kĩ lưỡng <br /> về Nhượng
    quyền Trực tuyến, đối tác tiến <br /> hành đăng kí với
    HanaGold`,
    fr_step_b: 'Thẩm định',
    fr_step_b_des: `Đây là bước hỗ trợ đối tác để xác định mức <br /> phù hợp
    với mô hình kinh doanh Nhượng quyền <br />
    Trực tuyến và đủ năng lực để hợp tác`,
    fr_step_c: 'Ký Kết Và Công Bố Đối Tác',

    fr_step_c_des: `Ký hợp đồng hợp tác và công bố tin tức <br /> chính thức
    trở thành đối tác Nhượng <br /> quyền Trực tuyến của
    HanaGold`,
    fr_step_d: 'Đào Tạo & Triển Khai Kinh Doanh',
    fr_step_d_des: `Đối tác được tham gia các buổi đào tạo <br /> kiến thức sản phẩm và kỹ năng, đồng thời <br /> bàn giao công cụ kinh doanh`,
    faq: 'Câu Hỏi Thường Gặp',
    faq_list: 'Danh mục câu hỏi',

    faq_list_a: 'Mô hình',
    faq_list_b: 'Sản phẩm',
    btn_a: 'Chính sách',

    /* 
    
    dấdassa

    */

    faq_list_a_title_a: 'Nhượng quyền Trực tuyến là gì?',
    faq_list_a_title_des_a:
      'Mô hình nhượng quyền kinh doanh thương hiệu HanaGold trên nền tảng trực tuyến. Mô hình giúp nhà đầu tư có thể dễ dàng sở hữu 1 cửa hàng kinh doanh vàng trang sức với số vốn nhỏ, linh hoạt về thời gian và địa điểm kinh doanh.',

    faq_list_a_title_b: 'Giá trị cốt lõi của Nhượng quyền Trực tuyến là gì?',
    faq_list_a_title_des_b: ` - Mang đến cơ hội kinh doanh vàng cực kỳ dễ
    dàng, đơn giản, tiện lợi cùng HanaGold
    <br />
    <br />
    - Trao cơ hội khởi nghiệp kinh doanh vàng trên
    nền tảng sẵn có của HanaGold
    <br />
    <br />- Cùng HanaGold tạo nên chuỗi cửa hàng
    nhượng quyền vàng online trên khắp Việt Nam và
    Quốc tế`,

    faq_list_a_title_c: 'Mô hình kinh doanh phù hợp với đối tượng nào?',
    faq_list_a_title_des_c: ` - Muốn kinh doanh nhưng chưa biết bắt đầu từ đâu
    và hạn chế vốn đầu tư, kỹ năng quản lý….
    <br />
    <br />- Muốn có thêm thu nhập thông qua hình
    thức kinh doanh trực tuyến
    <br />
    <br />- Những người đang có cơ sở kinh doanh
    vàng, nữ trang… và muốn mở rộng mô hình kinh
    doanh trên online
    <br />
    <br />- Những người thích vừa có thể tự chủ kinh
    doanh, vừa linh hoạt thời gian, không phụ thuộc
    địa điểm cố định`,

    faq_list_a_title_d:
      'Vì sao tôi nên tham gia hợp tác Nhượng quyền Trực tuyến?',
    faq_list_a_title_des_d: ` - Khởi nghiệp kinh doanh vàng với số vốn rất nhỏ
    chỉ 30.000.000đ
    <br />
    <br />- Tối ưu chi phí vận hành, quản lý đơn
    giản với mô hình kinh doanh Nhượng quyền Trực
    tuyến cùng HanaGold
    <br />
    <br />- Chính sách hoa hồng cực kỳ hấp dẫn dành
    cho đối tác
    <br />
    <br />- Phát triển thương hiệu cá nhân cùng
    HanaGold
    <br />
    <br />- Được hỗ trợ từ A- Z`,
    faq_list_a_title_e: 'Quyền lợi khi tôi tham gia là gì?',
    faq_list_a_title_des_e: `                               - Kinh doanh vàng trang sức 24/7 ngay trên nền
    tảng trực tuyến mà không phải đầu tư chi phí quá
    lớn cho cửa hàng
    <br />
    <br />
    - Được sử dụng nền tảng, thương hiệu HanaGold để
    kinh doanh với chính sách hoa hồng cao nhất
    <br />
    <br />- Đặc quyền hỗ trợ nhanh chóng các vấn đề
    liên quan đến khách hàng, nhân sự hỗ trợ riêng
    <br />
    <br />- Thu hồi vốn nhanh chóng và thu nhập cao
    khi Nhượng quyền Trực tuyến xây dựng và phát
    triển thành công tệp khách hàng thân thiết
    <br />
    <br />
    - Thông tin của đối tác Nhượng quyền Trực tuyến
    sẽ được công bố và hiển thị ở website HanaGold
    đảm bảo cơ sở bảo chứng từ thương hiệu
    <br />
    <br />- Được hỗ trợ training và truyền thông
    trong suốt quá trình kinh doanh cùng HanaGold.`,
    faq_list_a_title_f: 'Gói nhượng quyền bao gồm những gì?',
    faq_list_a_title_des_f: `
    - Vốn đầu tư
    <br />
    <br />
    - Tài khoản Nhượng quyền Trực tuyến HanaGold
    <br />
    <br />- Hợp đồng Nhượng quyền Trực tuyến
    <br />
    <br />- Giấy chứng nhận đối tác Nhượng quyền
    Trực tuyến
    <br />
    <br />
    - Nền tảng công nghệ, tài liệu kinh doanh
    <br />
    <br />- Combo gói hỗ trợ (hình ảnh, truyền
    thông, training…)`,
    faq_list_a_title_g: 'Chính sách hoa hồng như thế nào?',
    faq_list_a_title_des_g: ` Mời Đối tác xem thêm
    <a
      href="https://drive.google.com/file/d/1OfPFfUR1wesk3I37vSs3KMnkg3NTxBWs/view"
      target="_blank"
    >
      tại đây
    </a>`,
    faq_list_a_title_h:
      'Quy trình đăng ký trở thành Nhượng quyền Trực tuyến thế nào?',
    faq_list_a_title_des_h: ` <h6>Bước 1: ĐĂNG KÝ</h6>
    <p>
      - Sau khi tìm hiểu và được tư vấn kĩ lưỡng về E-
      Franchise, đối tác tiến hành đăng kí với
      HanaGold
    </p>

    <h6>Bước 2: THẨM ĐỊNH</h6>
    <p>
      - Đây là bước hỗ trợ đối tác để xác định mức phù
      hợp với mô hình kinh doanh E- Franchise và đủ
      năng lực để hợp tác
    </p>
    <h6>Bước 3: KÝ KẾT</h6>
    <p>
      - Ký hợp đồng hợp tác và công bố tin tức chính
      thức trở thành đối tác E- Franchise của HanaGold
    </p>
    <h6>Bước 4: ĐÀO TẠO</h6>
    <p>
      - Đối tác được tham gia các buổi đào tạo kiến
      thức sản phẩm và kỹ năng, đồng thời bàn giao
      công cụ kinh doanh
    </p>`,

    /* 
    
    dấdassa

    */
    faq_list_b_title_a: 'Sản phẩm kinh doanh là gì?',
    faq_list_b_title_des_a: `  <p>Ứng dụng HanaGold - Tích lũy vàng 4.0</p>`,

    faq_list_b_title_b: 'Tôi cần sử dụng các tính năng nào để kinh doanh?',
    faq_list_b_title_des_b: `
    <h6>
                                Đối tác sẽ kinh doanh dựa trên các tính năng:
                              </h6>
                              <p>
                                - Giới thiệu bạn bè
                                <br />
                                - Mua vàng
                                <br />
                                - Nuôi heo vàng
                                <br />
                                - Cùng hợp tác
                                <br />- Nâng cấp VIP
                                <br />- Ví hoa hồng
                              </p>
    `,
    faq_list_b_title_c: 'Các cấp độ tài khoản ở ứng dụng HanaGold là gì?',
    faq_list_b_title_des_c: ` <h6>
    Ứng dụng HanaGold có 3 cấp độ tài khoản, được
    sắp xếp từ thấp đến cao:
  </h6>
  <p>
    1. Tài khoản Người dùng
    <br /> 2. Tài khoản Đại sứ Kinh doanh
    <br />
    3. Tài khoản Nhượng quyền Trực tuyến
  </p>`,
    faq_list_b_title_d: 'Sự khác nhau giữa ba cấp độ tài khoản là gì?',
    faq_list_b_title_des_d: `<h6>1. Tài khoản “Người dùng”</h6>
    <p>
      - Chỉ hưởng hoa hồng tính năng "Giới thiệu bạn
      bè"
    </p>
    <h6>2. Tài khoản “Đại sứ Kinh doanh”</h6>
    <p>
      - Hưởng trọn chính sách hoa hồng kinh doanh
      <br />- Nhận 10% thưởng doanh số khi "Người
      dùng" rút tiền từ "Ví hoa hồng"
    </p>
    <h6>3. Tài khoản "Nhượng quyền Trực tuyến"</h6>
    <p>
      - Hưởng trọn chính sách hoa hồng kinh doanh
      <br />- Nhận 10% thưởng doanh số khi "Đại sứ
      Kinh doanh" rút tiền từ "Ví hoa hồng"
      <br />- Nhận 10% thưởng doanh số khi "Người
      dùng" rút tiền từ "Ví hoa hồng"
    </p>`,
    faq_list_b_title_e: 'Cách thức kinh doanh ra sao?',
    faq_list_b_title_des_e: `<p>
    Đối tác Nhượng quyền Trực tuyến sẽ phát triển
    người dùng và kinh doanh dựa trên các tính năng
    tích lũy sinh lời tại ứng dụng HanaGold để được
    hưởng mức hoa hồng cao nhất.
  </p>`,
    faq_list_b_title_f: 'Tôi sẽ nhận doanh thu ở đâu?',
    faq_list_b_title_des_f: ` <p>
    Doanh thu Nhượng quyền Trực tuyến sẽ được trả
    tại "Ví hoa hồng"
  </p>`,
    faq_list_b_title_g:
      'Kinh doanh dựa trên tính năng "Ví hoa hồng" là như thế nào?',
    faq_list_b_title_des_g: ` <p>
    Khi tài khoản "Người dùng" và "Đại sứ Kinh
    doanh" rút tiền từ "Ví hoa hồng", tài khoản
    "Nhượng quyền Trực tuyến" sẽ nhận 10% với cấp độ
    tài khoản
  </p>`,
    faq_list_b_title_h:
      'Đại sứ Kinh doanh có liên quan gì đến Nhượng quyền Trực tuyến?',
    faq_list_b_title_des_h: ` <p>
    Là đội ngũ kinh doanh giúp đối tác Nhượng quyền
    Trực tuyến gia tăng thu nhập thụ động từ tuyến
    dưới "Người dùng". Do đó, chỉ cần xây dựng đội
    ngũ này, đối tác Nhượng quyền Trực tuyến sẽ được
    hưởng hoa hồng cả "Đại sứ Kinh doanh" và "Người
    dùng".
  </p>`,

    sign: `Đăng Ký Trở Thành`,
    sign_a: `Đối Tác Nhượng Quyền`,
    btn_b: 'Đăng ký',
  },

  gold_price: {
    select_date: 'Chọn ngày',
    title: 'BẢNG GIÁ VÀNG HÔM NAY',
    title_a: 'Tỷ giá USD (Vietcombank)',
    buy: 'MUA',
    sell: 'BÁN',

    rank: 'HẠNG MỤC',
    header_title_a: 'GIÁ CHỐT PHIÊN',
    header_title_b: 'GIÁ CHỐT PHIÊN HÔM NAY',
    buy_a: 'Mua vào',
    buy_b: 'Bán ra',
    text: '(Đơn vị: Triệu đồng/lượng)',
    text_a: 'BIÊN ĐỘ MUA - BÁN',
    text_des: ' Bảo trợ bởi Hiệp Hội Kinh Doanh Vàng Việt Nam',
    share: 'Chia sẻ',
    down_a: 'Tải ảnh',
    down_b: 'Đang tải',
  },

  rate: {
    select_date: 'Chọn ngày',
    title: 'TỶ GIÁ NGOẠI TỆ',

    title_a: 'Mã ngoại tệ',
    title_b: 'Tên ngoại tệ',
    title_c: 'Mua tiền mặt',
    title_d: 'Mua chuyển khoản',
    title_e: 'Bán',

    text_des: '*Nguồn từ Vietcombank',
    share: 'Chia sẻ',
    down_a: 'Tải ảnh',
    down_b: 'Đang tải',
  },

  news: {
    title: 'TIN TỨC',
    read_more: 'Xem thêm',
    no_data: 'Không có dữ liệu',
    view: 'lượt xem',
    hot: 'TIN NỔI BẬT',
  },

  app_header: {
    header_a: 'Chào buổi sáng',
    header_b: 'Chào buổi tối',
    header_c: 'ĐĂNG XUẤT',
  },

  app_header_mb: {
    app_a: 'Vàng 4.0',
    app_b: 'sản phẩm',
    app_c: 'Biểu đồ',
    app_d: 'Cửa hàng',
    app_e: 'Khác',
  },

  dashboard_gold: {
    gold_a: 'Vàng hiện có',
    gold_b: 'Tiền hiện có',
    gold_c: 'Nạp tiền',
    gold_d: 'Mua vàng',
    gold_e: 'Chuyển vàng',
    gold_f: 'Nhận vàng',
    gold_g: 'Rút tiền',
    gold_h: 'Bán vàng',
    gold_i: 'Đại lý',
  },

  add_money: {
    add_a: 'Nạp Tiền',
    add_b_a: `Bạn đang có`,
    add_b_b: `yêu cầu nạp
    tiền chưa hoàn tất`,

    add_c_a: 'Tối thiểu từ',
    add_c_b: 'đến',

    add_d: 'PHƯƠNG THỨC THANH TOÁN',
    add_btn: 'Tiếp Tục',

    add_e: 'GỬI YÊU CẦU NẠP TIỀN',
    add_f: ` Vui lòng chuyển tiền ngân hàng tới số tài
    <br /> khoản và nội dung như sau. Sau đó nhấn “Quay lại” để
    trở ra`,
    add_g: 'Ngân hàng',
    add_h: 'Chi nhánh',
    add_i: 'Chủ tài khoản',
    add_k: 'Số tiền',
    add_l: 'Số tài khoản',
    add_m: 'Nội dung',
    add_n: ' QUAY LẠI',
    add_o: ' Hủy yêu cầu',

    add_suc_a: 'Tạo thành công',
    add_suc_b: 'Thời gian',
    add_suc_c: 'Mã giao dịch',
    add_suc_d: 'Quay lại trang chủ',

    add_f_a: 'Giao dịch đang được xử lý',
    add_f_b: 'Thời gian',
    add_f_c: 'Mã giao dịch',
    add_f_d: 'Quay lại trang chủ',
  },

  history_add: {
    title: ' Yêu Cầu Nạp Tiền',

    history_add_a: 'Mã giao dịch',
    history_add_b: 'Chuyển qua ngân hàng',
    history_add_c: 'Chủ tài khoản',
    history_add_d: 'Số tiền',
    history_add_e: '   Xem thêm',
  },

  buy_sell: {
    title: ' Mua Bán Vàng',
    buy: 'Mua',
    sell: 'Bán',
    money: 'Tiền hiện có',
    gold: 'Vàng hiện có',
    gold_a: 'chỉ',
    gold_b: 'Số vàng',
    gold_c: 'Số tiền',
    gold_d: '  * Giá trị mua vàng chỉ từ:',
    gold_e: '   * Giá trị vàng quy đổi từ:',
    gold_f: '  Tỷ giá Mua vào',
    gold_g: 'Tỷ giá Bán ra',
    gold_h: 'Tiếp tục',

    ok: 'Chúc Mừng!',
    if: 'XEM THÔNG TIN',
    select: '   Chọn đối tác',
  },

  deliver_gold: {
    title: 'Chuyển Vàng',
    receiver: 'Người nhận',
    save: 'Lưu vào danh bạ',
    gold: 'Số vàng cần chuyển',
    avi: 'Khả dụng',
    accept: 'Xác nhận',
    suc: 'Giao dịch thành công',
    suc_a: 'Đã chuyển thành công',
    suc_b: 'Chỉ cho',
    con: 'TIẾP TỤC GIAO DỊCH',
    back: 'Quay lại trang chủ',
    contacts: 'Danh bạ',
    del_contacts: ' Xóa khỏi danh bạ',
  },

  withdraw: {
    title: 'Nhận vàng',
    gold: 'Vàng hiện có',
    money: 'Tiền hiện có',
    gold_a: 'Số vàng cần quy đổi',
    lowest: ' Rút thấp nhất',
    highest: ' Rút cao nhất',
    buy: 'Tỷ giá Mua vào',
    sell: 'Tỷ giá Bán ra',
    spend: 'Chi phí giao dịch',
    total: 'Tổng phí',
    con: 'Tiếp Tục',
    add: 'CHỌN ĐỊA CHỈ NHẬN HÀNG',
    phone: 'Số điện thoại',
    next: 'Bước tiếp theo',
    inf: 'THÔNG TIN ĐỊA CHỈ NHẬN HÀNG',
    att: 'LƯU Ý',
    att_a: ' Thời gian rút vàng sẽ được đại lý gửi khi xác nhận đơn hàng.',
    att_b: ' Vui lòng mang theo CMND để hoàn thành thủ tục nhận vàng.',
    accept: 'Xác nhận',
    suc: 'Giao dịch thành công',
    suc_text: 'Bạn đã gửi yêu cầu nhận vàng thành công',
    back: 'Quay lại trang chủ',

    fail: 'Giao dịch không thành công',
    fail_text: 'Giao dịch thất bại. Vui lòng thử lại',
    re: '   GIAO DỊCH LẠI',
  },

  withdraw_money: {
    title: ' Rút tiền',
    total: 'TỔNG SỐ TIỀN:',
    lowest: 'Tối thiểu từ',
    highest: 'Tối đa',

    to: 'đến',
    spend: 'Chi phí giao dịch',
    trans: 'giao dịch',
    con: 'Tiếp tục',
    acc: 'TÀI KHOẢN NHẬN',
    err: 'Bạn chưa liên kết tài khoản ngân hàng !',
    suc: 'thành công',
    req: 'Yêu cầu rút tiền',
    con_a: 'Tiếp tục giao dịch',
  },

  agency: {
    title: 'Đại lý',
    tab_a: 'Rút vàng',
    tab_b: 'Chuyển Vàng',
    tab_c: 'Hoa Hồng',

    quantity: 'Số lượng',
    time: 'Thời gian',
    phone: 'Số điện thoại/Email',
    details: 'Chi tiết',
    suc: 'Thành công',
    code: 'Mã giao dịch',
    gold: 'Số vàng',
    auth: 'Chủ tài khoản',
    spend: 'Phí giao dịch',
    free: 'Miễn phí',
    more: 'Xem thêm',
    money: 'Số tiền nhận được',
    gold_a: 'Số vàng mua',

    req_text: 'YÊU CẦU RÚT VÀNG',
    quantity_a: 'Số lượng rút',
    status: 'Trạng thái',
    name: 'Tên người rút',
    email: 'SĐT/Email',
    add: 'Địa chỉ',
    date: 'Ngày rút',
    note: 'Ghi chú',
    reject: 'Từ chối',
    accept: 'Xác nhận',
    is_accept: ' Xác nhận Người dùng nhận vàng',
    back: 'Quay lại',
  },

  product: {
    title: 'Sản phẩm',
    all: 'Tất cả',
    cat: 'Danh mục',
    related_product: 'Sản phẩm liên quan',
    item_a: 'Dây',
    item_b: 'Kiềng',
    item_c: 'Lắc',
    item_d: 'Mặt',
    item_e: 'Nhẫn',
    item_f: 'Vòng',

    price_a: '1 Triệu - 3 Triệu',
    price_b: '3 Triệu - 5 Triệu',
    price_c: '5 Triệu - 10 Triệu',
    price_d: 'Trên 10 Triệu',
    price_f: 'Tất cả giá',
    price_e: 'Tất cả giá',

    price_text: ' Giá',

    cat_a: 'Tất cả',
    cat_b: 'Vàng 18k',
    cat_c: 'Vàng hồng',
    cat_d: 'Bạc nguyên chất',
    cat_e: 'Bạc xi bạch kim',
    cat_f: 'Bạc xi 2 màu',

    type_a: 'Tất cả chất liệu',
    type_b: 'Vàng',
    type_c: 'Bạc',
    type_d: 'Kim Cương',
    type_e: 'Đồng',

    res_a: 'kết quả của',
    res_b: 'sản phẩm',
    more: 'Xem thêm',
  },

  chart: {
    title: 'Biểu đồ',
    overall: 'TỔNG QUAN',
    price: 'GIÁ VÀNG',
    news: 'TIN TỨC',

    text_t: 'GIÁ VÀNG TRONG NƯỚC',
    details: 'Xem chi tiết',
    table_a: 'LV • x10/LƯỢNG',
    table_b: 'MUA',
    table_c: 'BÁN',

    hot: 'TIN NỔI BẬT',
    all: 'Xem tất cả',
    view: 'lượt xem',

    price_a: 'GIÁ VÀNG TRONG NƯỚC (24K)',
    price_b: 'GIÁ VÀNG MIẾNG',
  },

  store: {
    title: 'Cửa hàng',
  },

  setting: {
    title: 'Cài đặt',
    gen: 'CÀI ĐẶT CHUNG',
    sec: 'BẢO MẬT',

    active: 'Đã xác thực',
    not_active: 'Chưa xác thực',

    profile: ' Hồ sơ cá nhân',
    name: 'Họ tên',
    mail: 'Email',
    phone: 'Số điện thoại',
    date: 'Ngày sinh',
    add: 'Địa chỉ',
    save: 'LƯU THAY ĐỔI',

    ps: ' Đổi mật khẩu',
    c_ps: 'Mật khẩu hiện tại',
    n_ps: 'Mật khẩu mới',
    r_n_ps: 'Xác thực mật khẩu',
    btn: 'ĐỔI MẬT KHẨU',

    his: '  Lịch sử giao dịch',
    link: 'Liên kết thẻ ngân hàng',
    link_t: 'Tài Khoản Ngân Hàng',
    link_l: 'LIÊN KẾT NGÂN HÀNG',
    btn_add: 'Thêm tài khoản',
    m_link_t: 'thêm mới tài khoản ngân hàng',
    bank: 'Ngân hàng',
    e_bank: 'Bấm để chọn',
    p_bank: 'Tìm theo ngân hàng',
    n_bank: 'Số tài khoản',
    a_bank: 'Họ tên chủ thẻ',
    add_c: 'Thêm',
    back: 'Trở về',

    fr: 'Giới thiệu bạn bè',
    log: 'Đăng Xuất',

    very_a: ' Xác thực tài khoản (KYC)',
  },

  history_trans: {
    title: 'Lịch Sử Giao Dịch',
    cat_a: 'MUA VÀNG',
    cat_b: 'BÁN VÀNG',
    cat_c: 'NHẬN VÀNG',
    cat_d: 'NẠP TIỀN',
    cat_e: 'RÚT TIỀN',
    cat_f: 'KHÁC TIỀN',
    cat_g: 'MUA HÀNG',

    // MUA VÀNG

    buy_a: 'Ngày',
    buy_b: 'Mua',
    buy_c: 'Giá mua',
    buy_d: 'Phí giao dịch',
    buy_e: 'Miễn phí',
    buy_f: 'Tổng tiền',

    no_data: 'Không có dữ liệu',

    status_a: 'Đang chờ duyệt',
    status_b: 'Đã bị hủy',
    status_c: 'Thành công',
    status_d: '--',

    sell_a: 'Ngày',
    sell_b: 'Bán',
    sell_c: 'Giá bán',
    sell_d: 'Phí giao dịch',
    sell_e: 'Miễn phí',
    sell_f: 'Tổng tiền',

    gold_a: 'Mã giao dịch',
    gold_b: 'Số vàng cần rút',
    gold_c: 'Phí giao dịch',
    gold_d: 'Thông tin địa chỉ nhận hàng',
    gold_e: 'Địa chỉ',
    gold_f: 'Số điện thoại',
    gold_g: 'Hủy nhận vàng',

    pay_a: 'Nguồn tiền',
    pay_b: 'Mô tả',
    pay_c: 'Số tiền',

    trans_a: 'Ngân hàng',
    trans_b: 'Số tài khoản',
    trans_c: ' Hủy rút tiền',

    mon_a: 'Thông tin người gửi',
    mon_b: 'Số lượng',
    mon_c: 'Đã huỷ',
    mon_d: 'Tổng tiền',
    mon_e: ' Hủy đơn hàng',

    cancel_a: 'Chắc chắn muốn hủy đơn',
    cancel_b: 'Muốn thay đổi địa chỉ nhận hàng',
    cancel_c: 'Muốn thay đổi mã voucher',
    cancel_d: 'Muốn thay đổi sản phẩm trong đơn hàng',
    cancel_e: 'Thủ tục thanh toán quá rắc rối',
    cancel_f: 'Lý do khác',
    cancel_g: ' Hủy đơn hàng',
    cancel_h: ' Quay lại',

    modal_a: 'Chắc chắn muốn hủy yêu cầu nhận vàng?',
    modal_b: 'Hủy',
    modal_c: 'QUAY LẠI',
  },

  referral: {
    title: 'Giới Thiệu',
    ref_a: 'Đã giới thiệu (người)',
    ref_b: 'Thành công (người)',
    ref_c: 'Phần thưởng (chỉ vàng)',

    ref_d: 'Giới thiệu ngay',
    ref_e: 'Liên kết chia sẻ',
    ref_f: 'Mã giới thiệu',
    ref_g: 'Danh sách giới thiệu',

    all: 'TẤT CẢ',
    active: 'ĐÃ XÁC THỰC',
    non_active: 'CHƯA XÁC THỰC',
  },

  message: {
    suc_a: 'Copy thành công số tài khoản nhận',
    suc_b: 'Copy thành công số tiền',
    suc_c: 'Copy thành công nội dung chuyển khoản',
    suc_d: 'Xác nhận thành công',
    suc_e: 'Hủy thành công',
    suc_f: 'Lưu thành công.',
    suc_g: 'Gửi yêu cầu thành công',
    suc_h: 'Đã thêm vào giỏ hàng',
    suc_i: 'Đã xóa',
    suc_k: 'Bạn đã hủy thành công giao dịch',
    suc_l: 'có số tiền là',
    suc_m: 'Copy thành công số tài khoản nạp tiền!',
    suc_n: 'Copy thành công số tiền nạp!',
    suc_o: 'Copy thành công nội dung giao dịch nạp tiền!',
    suc_u: 'Đổi mật khẩu thành công.',
    suc_p: 'Thêm thành công',
    suc_q: 'Đã copy mã giới thiệu.',
    suc_r: 'Thêm địa chỉ thành công',
    suc_s: 'Xóa địa chỉ thành công',

    err: 'Thất bại',
    err_a: 'Lưu thất bại.',
    err_b: 'Bạn chưa có sản phẩm nên không thể chuyển bước tiếp theo',
    err_c: 'File size > 1 MB!',
    err_d: 'Bạn chưa nhập đúng số tiền!',
    err_e: 'Xác nhận thất bại',
    err_f: 'Vui lòng nhập số lượng lớn hơn 0',
    err_g: 'Mật khẩu mới không trùng khớp!',
    err_h: 'Phải có ít nhất một địa chỉ!',
    err_i: 'Không có sản phẩm nào trong giỏ!',
    err_k: 'Mật khẩu không được bỏ trống !',
    err_l: 'Mật khẩu mới không khớp với mật khẩu xác nhận!',
    err_m: 'Vui lòng nhập đúng',
    err_n: 'Vui lòng nhập số tài khoản',

    val_a: 'Sai định dạng email!',
    val_b: 'Chưa nhập email!',
    val_c: 'Chưa nhập số điện thoại!',
    val_d: 'Chưa nhập mật khẩu!',
    val_e: 'Mật khẩu không khớp!',
    val_f: 'Chưa nhập email !',
    val_g: 'Sai định dạng ngày sinh',
    val_h: 'Vui lòng nhập ngày sinh',
    val_i: 'Hãy chọn người nhận!',
    val_k: 'Chưa nhập số tài khoản!',
    val_l: 'Tài khoản không tồn tại',
    val_m: 'Chưa nhập họ và tên!',
    val_n: 'Chưa nhập tài khoản!',
    val_o: 'Sai định dạng tài khoản!',
    val_p: 'Chưa nhập OTP!',
    val_q: 'Mã OTP có 6 kí tự!',
    val_r: 'Sai định dạng tên đại lý!',
    val_s: 'Sai định dạng địa chỉ!',
    val_t: 'Mật khẩu không được bỏ trống!',
    val_u: "'Hãy nhập địa chỉ'",

    pla_a: 'Họ và tên',
    pla_b: 'Họ tên',
    pla_c: 'Bạn đang tìm sản phẩm nào?',
    pla_d: 'Địa chỉ email',
    pla_e: 'Số điện thoại',
    pla_f: 'Nội dung',
    pla_g: 'Địa chỉ',
    pla_h: 'Nhập số tiền',
    pla_i: '+ Thêm Email',
    pla_k: '+ Thêm Email',
    pla_l: 'Nhập số vàng',
    pla_m: 'Tìm kiếm',
    pla_n: 'Nhập số điện thoại hoặc email',
    pla_o: 'Nhập mật khẩu',
    pla_p: 'Xác thực mật khẩu',
    pla_q: 'Nhập mật khẩu mới',
    pla_r: 'MÃ XÁC THỰC',
    pla_s: 'Nhập mã OTP',
    pla_t: 'Công việc hiện tại',
    pla_u: 'Tìm kiếm...',
    pla_v: 'Mã giảm giá',
    pla_w: 'Lời nhắn của bạn',
    pla_x: 'Ngày sinh: DD/MM/YYYY',
    pla_y: 'Cửa hàng',
  },

  tutorial_purchase: {
    title: 'HƯỚNG DẪN MUA HÀNG',
    des: 'HƯỚNG DẪN MUA HÀNG',
    des_a:
      ' Với phương châm An toàn - Tiện lợi - Nhanh chóng HanaGold mang đến những trải nghiệm mua bán trang sức, mỹ nghệ bằng công nghệ cho khách hàng tốt nhất. Khách hàng có thể sở hữu các sản phẩm HanaGold bằng các cách sau:',
    step_a: `
    <p>Cách 1: Đối với các sản phẩm trang sức, mỹ nghệ</p>
    <span>
      Quý khách hàng có thể đặt hàng trực tiếp tại website
      www.hanagold.vn hoặc trên các trang thương mại điện tử tên
      shop “HanaGold Official” tại Tiki, Lazada, Shopee.
    </span>`,

    step_b: ` <p>Cách 2: Đối với sản phẩm Đồng vàng HanaGold tích lũy</p>
    <span>
      Quý khách hàng có thể tải App HanaGold trên CHPlay và App
      store để tiến hành mua vàng tích lũy. Ngoài ra, quý khách
      có thể chọn mua tích lũy tại VNDC wallet pro App (kênh đối
      tác HanaGold).
    </span>`,
    step_c: `<p>Cách 3: Liên hệ trực tiếp</p>
    <span>
      Để nhanh chóng hơn, quý khách hàng có thể gọi điện thoại
      trực tiếp đến hotline 0889028009 để đặt hàng hoặc gửi
      email contact@hanagold.vn.
    </span>`,
  },

  tutorial_payment: {
    title: 'Hướng dẫn thanh toán',
    des:
      'Tại HanaGold, khách hàng được cung cấp nhiều cách thức thanh toán trực tuyến, linh hoạt thuận tiện cho nhu cầu mua bán sản phẩm, dịch vụ như: Thanh toán tiền mặt trực tiếp, thanh toán qua thẻ ATM (có đăng ký Internet Banking) và thanh toán khi nhận hàng (COD).',

    step_a: `<h3>Thanh toán tiền mặt</h3>
    <p>
      Phương thức này áp dụng cho các khách hàng mua hàng hóa trực
      tiếp tại cửa hàng HanaGold. Nhân viên thu ngân sẽ hỗ trợ tận
      tình cho quý khách.
    </p>`,
    step_b: `<h2>
    Thanh toán online qua thẻ ATM (Có đăng ký Internet Banking)
  </h2>
  <p>
    Phương thức này áp dụng cho những khách hàng đặt hàng và mua
    sản phẩm trang sức, mỹ nghệ trên website HanaGold. Quy trình
    như sau:
  </p>
  <p>
    <b>Bước 1:</b> Quý khách chọn mẫu mã sản phẩm yêu thích và
    thêm vào giỏ hàng
  </p>
  <p>
    <b>Bước 2:</b> Chọn hình thức thanh toán online qua internet
    banking
  </p>
  <p>
    <b>Bước 3:</b> Tiến hành điền thông tin theo yêu cầu và xác
    thực giao dịch
  </p>
  <p>
    <b>Bước 4:</b> Hoàn thành thanh toán đơn hàng và chờ xác
    nhận đơn hàng.
  </p>`,

    step_c: `
    <h3>QUY ĐỊNH VỀ HOÀN TRẢ TIỀN KHI THANH TOÁN TRỰC TUYẾN</h3>
                  <p>
                    <b>Dư tiền, hoàn trả sản phẩm</b>
                  </p>
                  <p>
                    Trong trường hợp quý khách hàng đã mua hàng &amp; thanh toán
                    trực tuyến thành công nhưng dư tiền, hoặc trả sản phẩm,
                    HanaGold sẽ thực hiện hoàn tiền cho quý khách như sau:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Đối với thẻ ATM: HanaGold sẽ hoàn lại vào tài khoản quý
                        khách cung cấp và thời gian khách hàng nhận được tiền
                        hoàn từ 05 - 07 ngày (trừ Thứ 7, Chủ Nhật và Ngày lễ) kể
                        từ khi HanaGold nhận được đề nghị của khách hàng. Nếu
                        qua thời gian trên không nhận được tiền, khách hàng liên
                        hệ ngân hàng để giải quyết. HanaGold sẽ hỗ trợ cung cấp
                        thông tin (nếu có).
                      </p>
                    </li>
                    <li>
                      <p>
                        Đối với thẻ ATM: HanaGold sẽ hoàn lại vào tài khoản quý
                        khách cung cấp và thời gian khách hàng nhận được tiền
                        hoàn từ 05 - 07 ngày (trừ Thứ 7, Chủ Nhật và Ngày lễ) kể
                        từ khi HanaGold nhận được đề nghị của khách hàng. Nếu
                        qua thời gian trên không nhận được tiền, khách hàng liên
                        hệ ngân hàng để giải quyết. HanaGold sẽ hỗ trợ cung cấp
                        thông tin (nếu có).
                      </p>
                      </li>
                  </ul>`,

    step_d: `<p>
    <b>
      HanaGold không nhận được tiền nhưng khách hàng bị trừ tiền
    </b>
  </p>
  <p>
    Trường hợp khách hàng thanh toán không thành công (phía ngân
    hàng nhận của HanaGold không ghi nhận được tiền) nhưng tài
    khoản của khách hàng bị trừ tiền. Khách hàng vui lòng liên
    hệ ngân hàng về số tiền thanh toán mua hàng đó.
  </p>`,
    step_e: `<p>
  <b>
    Khách hàng ghi sai nội dung, không phải là người thanh
    toán
  </b>
</p>
<p>
  Trường hợp khách hàng không phải là người thanh toán trực
  tiếp (khách hàng nhờ người thân thanh toán hộ) thì vui lòng
  ghi đúng hướng dẫn về nội dung hoặc mã đơn hàng (nếu có) để
  HanaGold xử lý đúng đơn hàng. Các trường hợp ghi sai nội
  dung hoặc mã đơn hàng sẽ tiếp nhận xử lý trong vòng 03 - 05
  ngày làm việc (trừ Thứ 7, Chủ Nhật và Ngày lễ).
</p>`,
  },

  tutorial_preserve: {
    title: 'Hướng dẫn bảo quản trang sức',
    step_a: ` <p>
    <b>Trang sức có gắn ngọc trai</b>
  </p>
  <p>
    Do viên ngọc trai được bọc một lớp xà cừ là chất hữu cơ
    nên khi sử dụng bạn nên tránh không để tiếp xúc với các
    loại nước hoa, sữa dưỡng da, nước lau sơn móng tay, các
    loại keo xịt tóc, chanh, giấm, … và các loại chất tẩy hoặc
    hóa chất dễ bay hơi khác. Vì nếu tiếp xúc với các chất này
    viên ngọc trai sẽ bị bào mòn dần. Tốt nhất trong thời gian
    sử dụng, bạn nên ngâm rửa bằng nước ấm (không nên dùng bàn
    chải) sau đó lau nhẹ bằng vải mềm cotton thì viên ngọc
    trai sẽ bóng đẹp tự nhiên.
  </p>`,
    step_b: `<p>
  <b>Trang sức có gắn đá quý / bán quý</b>
</p>
<p>
  Trang sức có gắn các loại đá quý/ bán quý như: Ruby,
  Sapphire, Spinell, Tourmaline, Garnet, Peridot, Amethyst,
  Citrine, Topaz...
</p>
<p>
  Khi sử dụng không nên để trang sức đá quý, đá bán quý va
  chạm vào các vật cứng khác để tránh bị xước hay có tì vết
  sẽ khiến viên đá không còn đẹp và giảm đi giá trị. Nên làm
  sạch các sản phẩm đá quý, đá bán quý bằng bàn chải mềm và
  nước xà phòng ấm. Để tăng độ sáng bóng cho các loại đá quý
  và bán quý, bạn có thể lau bằng dầu không màu hoặc sáp
  ong.
</p>`,
    step_c: `<p>
<b>Trang sức có gắn đá Emerald, Opal</b>
</p>
<p>
Bạn nên bảo quản sản phẩm như các loại đá quý, đá bán quý
trên. Tuy nhiên, do đặc tính đặc biệt của các loại đá này
là mềm và cần phải lấy ra khỏi ổ hột trong quá trình làm
sạch, vì thế khi có nhu cầu làm mới trang sức, quý khách
nên đến các cửa hàng của HanaGold để món trang sức của
mình được chăm sóc hoàn hảo nhất.
</p>`,
    step_d: `<p>
<b>Trang sức kim cương</b>
</p>
<p>
Kim cương không thể bị mài mòn do các tác động bình thường
vì nó có độ cứng rất cao nhưng cần phải giữ sạch để có
được độ sáng vĩnh viễn. Bạn có thể ngâm trong nước xà
phòng ấm khoảng 30 phút sau đó rửa lại bằng nước sạch rồi
dùng vải mềm lau khô. Vì kim cương có độ cứng rất cao nên
nữ trang kim cương nên được bảo quản riêng biệt khỏi các
đồ trang sức khác để tránh trầy xước.
</p>`,
    step_e: ` <p>
<b>Trang sức vàng trắng</b>
</p>
<p>
Vàng trắng có màu trắng ngà nên khi được gia công thành
trang sức thường phải phủ lên bề mặt một chất có tên là
Rhodium để tạo nên màu trắng sáng giống như Platinum. Vàng
trắng khi sử dụng dưới tác động của nhiều yếu tố như thời
gian, mồ hôi và cả tính chất của vàng trắng nên có thể bị
ngả màu, vì vậy để trang sức vàng trắng luôn sáng đẹp cứ
khoảng từ 4 đến 6 tháng, bạn nên mang sản phẩm đến hệ
thống HanaGold để làm mới.
</p>`,
    step_f: `<p>
  <b>Trang sức vàng</b>
</p>
<p>Khi sử dụng nữ trang bằng vàng, bạn cần lưu ý:</p>
<p>
  Không nên để vàng tiếp xúc với thủy ngân, thuốc uốn tóc,
  nhuộm tóc và một số loại mỹ phẩm vì các chất này sẽ làm
  cho vàng bị ngả màu.
</p>
<p>
  Để vàng luôn sáng đẹp, mỗi tuần nên dùng bàn chải đánh
  răng loại mềm nhúng vào xà phòng hoặc kem đánh răng chải
  rửa trong và ngoài món nữ trang để tẩy đi bụi bẩn và mồ
  hôi bám vào. Khi nữ trang bị ướt thì không nên để nữ trang
  tự khô mà phải dùng vải thun cotton hoặc giấy mềm lau khô.
</p>
<p>
  Qua thời gian dài sử dụng, các món nữ trang có thể giảm đi
  độ sáng bóng, màu vàng bị nhạt, mờ. Để trở về trạng thái
  sáng bóng ban đầu chỉ có cách duy nhất là làm sạch, xi
  mới, đánh bóng bằng những dụng cụ chuyên ngành nữ trang.
  Tất cả các cửa hàng HanaGold đều có dịch vụ đánh bóng,
  siêu âm làm sạch miễn phí vĩnh viễn cho các sản phẩm
  HanaGold, riêng với dịch vụ xi mới, sản phẩm nhẫn cưới
  được miễn phí, còn các dòng sản phẩm khác khách hàng sẽ
  trả 1 mức phí hợp lý tùy theo chủng loại sản phẩm.
</p>`,

    step_g: `<p>
  <b>Trang sức bạc</b>
</p>
<p>
  Trang sức bạc đeo lâu ngày có thể bị mờ đi. Bạc bị oxy hóa
  khi tiếp xúc với không khí, ánh sáng mạnh và các hóa chất
  như keo xịt tóc, nước hoa, thuốc tẩy, nước biển,…
</p>
<p>
  Để món trang sức bạc luôn sáng đẹp, bạn nên ngâm và rửa
  nhẹ nhàng trong nước tẩy rửa nhẹ (nước tẩy rửa chén đĩa,
  ly tách) pha với nước sạch. Sau đó, rửa lại bằng nước sạch
  và dùng khăn mềm lau khô thật kỹ. Tuyệt đối không sử dụng
  nước tẩy rửa có tính chất tẩy mạnh.
</p>
<p>
  Để đảm bảo và duy trì vẻ đẹp của trang sức bạc, nên cất
  giữ và khi đeo tránh va chạm hoặc sử dụng bất kì dụng cụ
  nào tác động trực tiếp đến món trang sức.
</p>`,
  },

  tutorial_measuresize: {
    title: 'HƯỚNG DẪN ĐO SIZE TRANG SỨC',
    t_a: 'ĐO SIZE NHẪN',
    t_b:
      'Với hướng dẫn này bạn có thể dễ dàng mua sắm nhẫn vàng, nhẫn bạc, nhẫn cưới, nhẫn kim cương hay nhẫn đôi ngay trên online mà không còn gì phải lo ngại.',
    step_a: `<li>
    <p>
      <b>Bước 1:</b> Quý khách chọn chiếc nhẫn đeo vừa với ngón
      tay mong muốn
    </p>
  </li>
  <li>
    <p>
      <b>Bước 2:</b> Quý khách dùng thước đo đường kính bên
      trong của nhẫn
    </p>
  </li>
  <li>
    <p>
      <b>Bước 3:</b> Quý khách đối chiếu với bảng đo size nhẫn
      chuẩn.
    </p>
  </li>
  <li>
    <p>
      <b>Bước 4:</b> Quý khách điền size nhẫn theo chuẩn (Size
      số...) vào ô trống điền size nhẫn khi đặt hàng online.
    </p>
  </li>`,
    step_b: ` <h3>Đo size nhẫn bằng tờ giấy và thước</h3>
  <ul>
    <li>
      <p>
        <b>Bước 1:</b> Chuẩn bị một cây thước, 1 cây kéo, 1 cây
        bút &amp; một tờ giấy
      </p>
    </li>
    <li>
      <p>
        <b>Bước 2:</b> Cắt một mảnh giấy dài khoảng 10 cm và rộng
        1 cm.
      </p>
    </li>
    <li>
      <p>
        <b>Bước 3:</b> Sử dụng đoạn giấy vừa cắt để quấn sát quanh
        ngón tay muốn đo.
      </p>
    </li>
    <li>
      <p>
        <b>Bước 4:</b> Đánh dấu điểm giao nhau.
      </p>
    </li>
    <li>
      <p>
        <b>Bước 5:</b> Tháo ra dùng thước đo chiều dài của đoạn
        giấy từ điểm đầu cho đến phần đánh dấu. Lấy kết quả đo
        được chia cho 3,14. Sau đó đối chiếu với bảng SIZE NHẪN
        CHUẨN
      </p>
    </li>
  </ul>`,

    step_c: ` <h3>Những lưu ý khi đo</h3>
    <h4>1/ Kích cỡ ngón tay phụ thuộc nhiệt độ</h4>
    <p>
      Nhiệt độ là nguyên nhân gây ra việc đo kích thước nhẫn không
      chính xác. Khi thời tiết lạnh ngón tay của bạn có thể nhỏ hơn
      bình thường, bạn nên cộng thêm cho chu vi là 1 mm còn khi thời
      tiết nóng thì ngược lại, trừ đi 1 mm. Trường hợp xương khớp
      ngón tay của bạn to, thì bạn nên đo chu vi ở gần khớp (không
      phải trên khớp) Sao cho khi đeo nhẫn dễ vào nhưng không bị
      tuột mất.
    </p>
    <h4>2/ Chú ý độ dày của nhẫn</h4>
    <p>
      Độ dày của nhẫn cũng ảnh hưởng đến size ni của mỗi người. Đối
      với các loại nhẫn như nhẫn vàng, nhẫn bạc, độ dày sẽ khác
      nhau. Do đó, khi tiến hành đo, bạn cần phải chú ý và cho kích
      thước thêm hoặc bớt đi một khoản để khi đeo nhẫn có thể thoải
      mái mà không bị gò bó.
    </p>
    <h4>3/ Để ý những khớp tay</h4>
    <p>
      Các khớp tay có thể to hơn so với ở phía gốc của ngón. Do đó
      bạn cần phải đo kích thước ở khớp ngón và gốc ngón, sau đó lấy
      kích thước giữa 2 phần để chọn size nhẫn phù hợp. Lúc này tay
      đeo nhẫn của bạn sẽ có cảm giác thoải mái và không bị khó chịu
      khi co hoặc gập ngón tay.
    </p>
    <p>
      Trong trường hợp khớp xương ngón tay của bạn to, bạn nên đo
      chu vi ở gần khớp (không phải trên khớp) sao cho khi bạn đeo
      nhẫn thì nhẫn dễ vào và không bị tuột mất.
    </p>
    <h4>4/ Đo lại nhiều lần để có số liệu chính xác nhất</h4>
    <p>
      Khi đo size tay chắc chắn sẽ có những sai sót, vì vậy hãy cẩn
      thận đo từ 3-4 lần để tìm ra số đo chính xác nhất. Làm theo
      cách này, kích cỡ bạn đo được sẽ có độ chính xác cao từ
      85-95%.
    </p>`,

    t_c: 'HƯỚNG DẪN ĐO SIZE LẮC',
    t_d: 'Chiều dài của dây là 17 cm tương ứng với size 17',

    step_d: `<li>
    <h4>Size lắc tay phổ biến cho nữ: Size 16-18</h4>
    <p>16 cm - 17 cm - 18 cm</p>
  </li>
  <li>
    <h4>Size lắc chân phổ biến cho nữ: Size 23-25</h4>
    <p>23 cm - 24 cm - 25m</p>
  </li>
  <li>
    <h4>Size lắc chân phổ biến cho trẻ em: Size 13 -15</h4>
    <p>13 cm - 14 cm - 15 m</p>
  </li>`,

    step_e: `<p>
    <b>Lưu ý:</b>
  </p>
  <p>
    - Size lắc phổ biến của HanaGold thường có đối với lắc tay
    nữ là size 16,17,18; đối với lắc chân nữ là size 23 - 25 và
    đối với lắc tay trẻ em là size 13 - 15.
  </p>
  <p>
    - Trường hợp size lắc bạn đo được không có, HanaGold sẽ đặt
    làm trong thời gian tối đa là 2 tuần và chỉ áp dụng cho
    trang sức bằng vàng.
  </p>`,

    step_f: `<p>
    <b>Đo thủ công:</b>
  </p>
  <p>
    - Nếu bạn không có một chiếc lắc tay khác để đo, HanaGold
    giới thiệu bạn một cách nhưng lưu ý bạn áp dụng cách này sẽ
    không đưa ra được kết quả chính xác nhưng có thể sử dụng
    được.
  </p>
  <p>
    - Bạn sử dụng một miếng giấy nhỏ như trong hình minh họa
    trên đây, quấn chặt vòng theo vòng tay/vòng chân bạn, đo độ
    dài bằng thước kẻ như thao tác đã hướng dẫn ở cách 2. Sau
    khi chọn size lắc, nhân viên HanaGold sẽ gọi điện thoại xác
    nhận yêu cầu của bạn và tư vấn cụ thể để đảm bảo đáp ứng
    đúng yêu cầu của bạn.
  </p>`,

    t_e: 'HƯỚNG DẪN ĐO SIZE VÒNG',
    t_f: 'Đường kính lọt lòng của chiếc vòng (size vòng)',

    step_g: `<li>
    <h4>Kích thước vòng dành cho nữ: Size 52-55</h4>
    <p>52 mm - 53 mm - 54 mm - 55 mm</p>
  </li>
  <li>
    <h4>Kích thước vòng dành cho nam: Size 56-60</h4>
    <p>56 mm - 57 mm - 58 mm - 59 mm - 60 mm</p>
  </li>
  <li>
    <h4>Kích thước vòng dành cho trẻ em: Size 36-42</h4>
    <p>36 mm - 38 mm - Các size khác - 42 mm</p>
  </li>`,

    step_h: `<p>
  <b>Đo dựa vào vòng:</b>
</p>
<p>
  <b>Bước 1:</b> Bạn dùng thước để đo đường kính bên trong của
  chiếc vòng tay.
</p>
<p>
  <b>Bước 2:</b> Sau khi có kích thước, bạn đối chiếu số mm
  của thước với kích thước đường kính của bảng kích thước vòng
  như hình trên.
</p>
<p>
  <b>Bước 3:</b> Kích thước vòng tay của bạn chính là size số
  ghi dưới vòng tròn. Sau khi chọn size vòng, nhân viên
  HanaGold sẽ gọi điện thoại xác nhận yêu cầu của bạn và tư
  vấn cụ thể để đảm bảo đáp ứng đúng yêu cầu của bạn.
</p>`,

    step_i: `<h5>Cách đo thủ công:</h5>
<p>
  <b>Vòng tròn:</b>
</p>
<p>
  <b>Bước 1: </b> Dùng một sợi dây (hoặc thước dây/ chỉ/giấy
  bản nhỏ) quấn quanh cổ tay đeo vòng, đánh dấu chỗ tiếp giáp.
</p>
<p>
  <b>Bước 2:</b> Đo chiều dài sợi dây giữa 1 chỗ tiếp giáp.
</p>
<p>
  <b>Bước 3:</b> Lấy chiều dài đó chia cho 3.14 là sẽ ra đường
  kính vòng.
</p>
<p>
  <b>Bước 4:</b> Bạn đối chiếu đường kính bạn vừa đo (theo mm)
  với bảng kích thước vòng bên trên. Kích thước vòng của bạn
  tương ứng với size số ghi dưới vòng tròn.
</p>`,

    step_k: ` <p>
<b>Vòng Oval :</b>
</p>
<p>Bạn có thể đo chiều ngang cổ tay</p>
<p>
Kích thước vòng tay của bạn chính là size số ghi dưới vòng
tròn.
</p>`,

    t_g: `HƯỚNG DẪN ĐO SIZE DÂY CHUYỀN`,
    t_h: `Chiều dài của dây là 40 cm tương ứng với size 40`,

    step_l: ` <h4>Kích thước dây chuyền phổ biến</h4>
    <p>40 cm - 41 cm - 42 cm - 43 cm - 44 cm - 45 cm</p>`,
    step_m: ` <p>
    Nên có một chiếc dây chuyền/dây cổ khác để đo, cách chính
    xác nhất là bạn dùng một chiếc dây chuyền/dây cổ có độ rộng
    và kiểu dáng tương tự chiếc bạn định mua và làm theo các
    bước sau:
  </p>
  <p>- Bạn dùng thước để đo chiều dài đoạn dây.</p>
  <p>
    - Sau khi có kích thước, bạn đối chiếu số cm của thước với
    kích thước minh họa như hình trên
  </p>
  <p>
    - Kích thước dây chuyền của bạn chính là size số ghi bên
    dưới đường thẳng.
  </p>`,
    step_n: `<p>
    <b>Đo thủ công:</b>
  </p>
  <p>
    - Nếu bạn không có một dây chuyền/dây cổ khác để đo,
    HanaGold giới thiệu bạn một cách nhưng lưu ý bạn áp dụng
    cách này sẽ không đưa ra được kết quả chính xác nhưng có thể
    sử dụng được.
  </p>
  <p>
    - Bạn sử dụng một miếng giấy nhỏ, quấn chừng quanh cổ theo
    vòng dây mà bạn muốn đeo, sau đó đo độ dài bằng thước kẻ như
    thao tác đã hướng dẫn ở cách 2. Sau khi chọn size dây
    chuyền/dây cổ, nhân viên HanaGold sẽ gọi điện thoại xác nhận
    yêu cầu của bạn và tư vấn cụ thể để đảm bảo đáp ứng đúng yêu
    cầu của bạn.
  </p>`,
  },

  tutorial_recieve: {
    title: 'CHÍNH SÁCH GIAO NHẬN HÀNG',

    step_a: `<h4>
    Giao nhận trực tiếp tại cửa hàng HanaGold, các showroom Đại
    lý HanaGold
  </h4>
  <p>
    Quý khách mua hàng tại cửa hàng HanaGold và các showroom Đại
    lý sẽ được giao nhận tại thời điểm hoàn thành thanh toán đơn
    hàng. Sản phẩm luôn được kiểm tra và xác nhận trước khi nhận
    hàng và rời khỏi cửa hàng.
  </p>`,

    step_b: `<h4>Giao tận nhà</h4>
  <p>Các sản phẩm hỗ trợ giao hàng tại nhà:</p>
  <ul>
    <li>
      <p>Sản phẩm trang sức bạc</p>
    </li>
    <li>
      <p>
        Sản phẩm quà tặng kim hoàn bao gồm các vật phẩm phong
        thủy mạ vàng, cúp lưu niệm, huân chương mạ vàng, tranh
        mạ vàng,…
      </p>
    </li>
  </ul>
  <p>
    *Lưu ý: HanaGold giao hàng trên toàn quốc cho tất cả các đơn
    hàng trên, thời gian nhận hàng và chi phí vận chuyển phụ
    thuộc vào đơn vị vận chuyển
  </p>`,

    step_c: ` <h4>DỊCH VỤ BẢO HIỂM HÀNG HÓA</h4>
  <p>
    <b>Dịch vụ Bảo hiểm hàng hóa và giao hàng bảo đảm 100%</b>
  </p>
  <ul>
    <li>
      <p>
        Để bảo vệ quyền lợi của Khách hàng khi mua trang sức giá
        trị cao, toàn bộ sản phẩm của HanaGold thông qua đối tác
        vận chuyển đều được HanaGold mua bảo hiểm hàng hoá.
      </p>
    </li>
    <li>
      <p>
        Luôn cam kết đền bù 100% giá trị hàng hóa trong trường
        hợp thất lạc.
      </p>
    </li>
  </ul>`,

    step_d: ` <p>
  <b>Quy cách đóng gói hàng hóa tại HanaGold</b>
</p>
<ul>
  <li>
    <p>
      Toàn bộ sản phẩm HanaGold đã được kiểm tra chất lượng
      trước khi đóng gói niêm phong 2 lớp.
    </p>
  </li>
  <li>
    <p>
      Lớp 1: Sản phẩm của quý khách được đặt vào hộp trang sức
      HanaGold và được niêm phong lớp 1 bằng Tem vỡ* HanaGold.
    </p>
  </li>
  <li>
    <p>
      Lớp 2: Hộp vận chuyển sẽ được gói kín, niêm phong bằng
      02 Tem vỡ* HanaGold ở giữa mỗi nắp hộp và dán băng keo
      cẩn thận
    </p>
  </li>
</ul>
<p>
  <b>Lưu ý:</b>
</p>
<ul>
  <li>
    <p>
      Khi kiểm tra hàng , quý khách nên Quay lại Video để
      những vấn đề phát sinh được dễ dàng xử lý.
    </p>
  </li>
  <li>
    <p>
      *Tem vỡ: Là một loại tem đặc biệt dùng để dán lên các
      sản phẩm nhằm mục đích đảm bảo hàng hóa chưa bị bóc niêm
      phong. Đặc điểm của nó khá giòn, khi dán tem lên sản
      phẩm sẽ ko thể bóc ra, nếu cố tình bóc thì tem sẽ vỡ vụn
      ra.
    </p>
  </li>
</ul>`,
    step_e: `<p>
<b>Thời gian giao hàng</b>
</p>
<ul>
<li>
  <p>Khu vực Tp.HCM, Hà Nội: 1-4 ngày.</p>
</li>
<li>
  <p>Khu vực tỉnh thành khác: 2-6 ngày.</p>
</li>
<li>
  <p>
    Thời gian giao hàng được bắt đầu tính từ lúc tư vấn viên
    HanaGold liên hệ xác nhận đơn hàng thành công
  </p>
</li>
<li>
  <p>
    Thời gian giao hàng trên chỉ mang tính chất tham khảo,
    thực tế có thể dao động sớm hoặc muộn hơn tùy theo tình
    trạng tồn kho sản phẩm, địa chỉ giao hàng và một số điều
    kiện khách quan mà HanaGold không thể kiểm soát được (Ví
    dụ: thiên tai, lũ lụt, hỏng hóc phương tiện...).
  </p>
</li>
</ul>`,

    step_f: ` <h2>
    Chính sách xem và kiểm tra hàng hóa trước khi nhận hàng
  </h2>
  <p>
    Nhằm đáp ứng nhu cầu và bảo vệ tối đa quyền lợi khách hàng
    khi sử dụng dịch vụ HanaGold đã triển khai chính sách hỗ trợ
    việc xem và kiểm tra hàng hóa khi giao hàng.
  </p>
</li>
<li>
  <p>
    Khách hàng khi nhận hàng từ nhân viên vận chuyển: vui lòng
    kiểm tra hộp hàng còn nguyên vẹn 02 tem niêm phong, khách
    hàng có thể mở hàng để kiểm tra hàng hóa bên trong hộp hàng.
    Khuyến khích khách hàng quay lại Video trong suốt quá trình
    kiểm tra hàng để những vấn đề phát sinh được dễ dàng xử lý.
  </p>
</li>
<li>
  <p>
    HanaGold đảm bảo sản phẩm gửi đến khách hàng đã được kiểm
    tra chất lượng. Trong trường hợp hiếm hoi sản phẩm khách
    hàng nhận được có khiếm khuyết, hư hỏng hoặc không như mô
    tả, HanaGold cam kết bảo vệ khách hàng bằng chính sách đổi
    trả và bảo hành.
  </p>`,
  },

  login: {
    log: 'ĐĂNG NHẬP',
    log_p: 'Bạn chưa có tài khoản ở HanaGold?',
    re: 'Đăng ký ngay',
    remem: 'Ghi nhớ',
    forgot: ' Quên mật khẩu',
    re_a: 'Đăng ký',
    text: 'Lời mời từ',
    re_b: 'Bạn đã có tài khoản ở HanaGold?',
    log_b: 'Đăng nhập ngay',
    create: 'TẠO TÀI KHOẢN',
    code: 'MÃ XÁC THỰC',
    code_text:
      'Vui lòng điền vào bên dưới mã xác thực vừa được gửi đến  email/số điện thoại của bạn',
    suc: ' Hoàn Tất',
    re_send: 'GỬI LẠI MÃ XÁC THỰC',
    suc_b: 'Thành công',
    text_b: `Mời bạn tải ứng dụng HanaGold tại App Store <br /> hoặc CH Play
    để đăng nhập nhận thưởng`,
    down: 'TẢI ỨNG DỤNG HANAGOLD',
    more: ' XEM THÊM VỀ HANAGOLD',

    p_a: 'Lấy lại mật khẩu',
    p_b: ' Vui lòng điền tài khoản của bạn',
    p_c: ' Vui lòng điền mã OTP đã được gửi về email/số điện thoại của bạn',
    p_d: 'Xác nhận',
    p_e: ' Gửi mã OTP',
    p_f: 'Đổi mật khẩu',
    p_g: 'GỬI LẠI MÃ XÁC THỰC',
  },

  info: {
    name_a: 'Ms. Nguyễn Phi Vân',
    name_b: 'Mr. Nguyễn Tuấn Quỳnh',
    in_a: `
   <h3>Ms. Nguyễn Phi Vân</h3>
   <h6>Chuyên gia Nhượng quyền Quốc Tế</h6>
   <p>
     Nguyễn Phi Vân đã từng giữ các vị trí cao cấp về quản trị
     thương hiệu, bán lẻ, nhượng quyền & phát triển kinh doanh
     cho các tập đoàn lớn tại các khu vực thị trường châu Á,
     Trung đông, châu Phi & Đông Âu.
   </p>
   <h4>Học vấn</h4>
   <p>- Tốt nghiệp MBA tại Úc.</p>

   <h4>Kinh nghiệm</h4>
   <p>
     Phi Vân tham gia vào nhiều dự án chính phủ về phát triển
     doanh nghiệp & kinh tế sáng tạo tại Đông Nam Á, hiện là cố
     vấn đề án 844 về phát triển hệ sinh thái khởi nghiệp Việt
     Nam, chủ tịch mạng lưới đầu tư thiên thần Đông Nam Á, và đại
     diện cấp cao Việt Nam tại diễn đàn đầu tư thiên thần toàn
     cầu.
     <br />
     <br />
     Ngoài ra, cô cũng là nhà đầu tư với danh mục đầu tư 24 công
     ty. Phi Vân đã đạt giải thưởng quốc tế 2015 về Lãnh đạo xuất
     sắc ngành bán lẻ của hiệp hội bán lẻ toàn cầu, giải thưởng
     2017 & 2018 100 lãnh đạo xuất sắc ngành bán lẻ toàn cầu, và
     giải thưởng Doanh nhân ASEAN xuất sắc 2019, và lọt vào Top
     100 phụ nữ có tầm ảnh hưởng nhất thế giới trong ngành nhượng
     quyền toàn cầu năm 2019 & 2020.
     <br />
     <br />
     Phi Vân là tác giả nhiều quyển sách tiếng Anh & tiếng Việt
     bao gồm Nhượng quyền khởi nghiệp - Con đường ngắn để bước ra
     thế giới vừa đạt giải sách hay quản trị 2019, Quảy gánh băng
     đồng ra thế giới, Go Global - An MSME Guide to Global
     Franchising, Tôi Tương Lai & Thế giới, Tôi đi tìm tôi, Nym -
     Tôi của tương lai, và gần đây nhất là Mở cửa tương lai.
   </p>

   <h4>Vai trò tại HanaGold</h4>
   <p>- Cố vấn cấp cao về Nhượng quyền</p>
   `,
    in_b: `
                  <h3>Mr. Nguyễn Tuấn Quỳnh</h3>
                  <h6>Tổng Giám Đốc Quỹ Go Global Franchise Fund</h6>
                  <p>
                    Doanh nhân Nguyễn Tuấn Quỳnh sinh ngày 7/9/1972, sinh ra tại
                    Bình Dương và lớn lên ở TP.HCM. Ông hiện đang là Chủ tịch
                    HĐQT Công ty Cổ phần Văn hóa Sách Sài Gòn - Saigon Books
                  </p>
                  <h4>Học vấn</h4>
                  <p>
                    - Tốt nghiệp tiến sĩ ngành quản trị kinh doanh
                    <br />
                    <br />
                    - Sinh viên xuất sắc trong lĩnh vực nghiên cứu khoa học Đại
                    học Kinh tế TP HCM.
                  </p>

                  <h4>Kinh nghiệm</h4>
                  <p>
                    Doanh nhân Nguyễn Tuấn Quỳnh là Phó Chủ tịch Hội Doanh nhân
                    trẻ TP.HCM, giảng viên và giám khảo nhiều cuộc thi khởi
                    nghiệp. Anh đã có 22 năm làm việc và lãnh đạo nhiều doanh
                    nghiệp lớn như: Saigon Petro, Saigon Gas, Vina Gas, SFC,
                    PNJ, PNC, Alpha Books… trước khi thành lập và điều hành
                    Saigon Books từ năm 2016. Đồng thời, anh còn là giảng viên
                    đại học, diễn giả và giám khảo của nhiều hội thảo, cuộc thi
                    về đề tài khởi nghiệp.
                    <br />
                    <br />
                    Ngoài các giải thưởng Doanh nhân được yêu thích nhất, khi
                    đang là TGĐ Công ty Cổ phần Nhiên liệu sài Gòn (SFC), anh
                    Nguyễn Tuấn Quỳnh còn là cây viết quen thuộc với độc giả.
                    Anh từng đạt giải Nhất cuộc thi viết tùy bút trên Báo Tuổi
                    trẻ các năm 2011, 2012; giải Nhất Báo chí TP.HCM, thể loại
                    chính luận, năm 2017.
                    <br />
                    <br />
                    Anh là tác giả của ba cuốn sách: Sống ở thể chủ động, xuất
                    bản năm 2014; Sống tích cực để yêu thương, xuất bản năm
                    2016; Cứ bay rồi sẽ cao (đồng tác giải), xuất bản năm 2018.
                  </p>

                  <h4>Vai trò tại HanaGold</h4>
                  <p>-Tổng Giám đốc Quỹ đầu tư Go Global Franchise Fund</p>
   `,
  },

  breadcrumb_home: 'Trang chủ',

  gold_tap: {
    heading_1: 'Tiên Phong Xu Hướng',
    heading_2: 'Tích Lũy GOLD',
    description:
      'Bắt đầu thu hoạch GOLD miễn phí, sử dụng trong hệ sinh thái vàng HanaGold.',
    button_search: 'Tìm hiểu ngay',
    start_harvest: 'Bắt đầu thu hoạch GOLD',
    scan_QR:
      'Quét QRCode hoặc nhập “Gold Tap Tap” trên thanh tìm kiếm tại ứng dụng Telegram.',
    benefit: 'Lợi ích sử dụng GOLD trong hệ sinh thái',
    list_benefit: {
      benefit_title_1: 'Mint NFT',
      benefit_description_1:
        'Người dùng có thể tạo ra các NFT độc đáo và có giá trị trên blockchain TON.',
      benefit_title_2: 'Nâng cấp Hạng VIP',
      benefit_description_2: 'Sử dụng token GOLD để đổi lấy các đặc quyền VIP.',
      benefit_title_3: 'Quy đổi E-Voucher',
      benefit_description_3: 'Quy đổi & nhận E-Voucher phần thưởng từ HanaGold',
      benefit_title_4: 'Quy đổi',
      benefit_description_4:
        'Người dùng có thể sử dụng GOLD để quy đổi XAU, HNG.',
      benefit_title_5: 'Giao dịch tại sàn',
      benefit_description_5:
        'Người dùng có thể quy đổi GOLD tại các sàn giao dịch được niêm yết.',
    },
    define_description:
      'Gold là tài sản điện tử của HanaGold dành cho cộng đồng, được kiếm miễn phí bằng cách nhấn vào màn hình và hoàn thành các nhiệm vụ mỗi ngày.',
    roadmap: 'Lộ trình phát triển',
    features_title: 'Tính Năng Nổi Bật',
    list_features: {
      feature_1: 'Tap & Earn GOLD miễn phí!',
      feature_2: 'Gia tăng GOLD khi farm và hoàn thành nhiệm vụ',
      feature_3: 'Nhận phần thưởng siêu to với đội nhóm!',
    },
    guides_1: 'Hướng Dẫn',
    guides_2: 'Sử Dụng',
    start_now: 'Bắt đầu ngay',
    list_steps: {
      title_step_1: 'Bước 01',
      description_step_1:
        'Truy cập Telegram. Nếu chưa, hãy tải & đăng ký tài khoản.',
      title_step_2: 'Bước 02',
      description_step_2: 'Nhập “Gold Tap Tap” tại thanh tìm kiếm.',
      title_step_3: 'Bước 03',
      description_step_3: 'Bắt đầu thu hoạch GOLD',
    },
    phase: {
      step: 'GIAI ĐOẠN',
      important: 'QUAN TRỌNG',
      t1: 'Ra mắt - khởi động',
      d1: 'Thu hoạch GOLD Tap Tap miễn phí',
      t2: '01 triệu người dùng',
      d2: 'Nhận GOLD',
      t3: '05 triệu người dùng',
      t4: '10 triệu người dùng',
      d4: 'Niêm yết các sàn giao dịch',
      t5: '20 triệu người dùng',
      d5:
        'Dùng GOLD để quy đổi E-Voucher, trả phí nâng VIP trong hệ sinh thái HanaGold.',
    },
    use: 'Sử dụng',
  },
  priority: {
    baner_title: 'Hanagold priority',
    baner_subtitle: 'khám phá',
    discover_title_line_1: 'Khám Phá',
    discover_title_line_2: 'Đặc Quyền Tinh Tế',
    discover_subtitle: 'khách hàng ưu tiên',
    discover_description:
      'Dịch vụ Khách hàng Ưu tiên – HanaGold Priority dành riêng các Khách hàng Cá nhân cao cấp của Công ty Cổ Phần Vàng Bạc Đá Quý HanaGold.',
    discover_item_1_title: 'Nền tảng tích lũy vàng',
    discover_item_1_description:
      'Mang đến trải nghiệm tiên phong tích lũy vàng trong thời đại số 4.0.',
    discover_item_2_title: 'Giải pháp tài chính ',
    discover_item_2_description:
      ' Được tinh chỉnh trọn vẹn theo từng nhu cầu của Quý khách.',
    discover_item_3_title: 'Bảo vệ tối ưu',
    discover_item_3_description:
      'Khai thác tối đa các nguồn lực tài chính, trải nghiệm những dịch vụ xứng tầm và an tâm',
    level_title: 'Cấp Bậc Tài Khoản',
    level_description:
      'HanaGold Priority được gọt giũa theo chuẩn mực mới nhất để nâng tầm trải nghiệm thượng lưu chỉ dành riêng cho Quý khách hàng cao cấp tại HanaGold.',
    level_label: 'CẤP BẬC',
    solution_title: 'Tận Hưởng Các Giải Pháp Toàn Diện Do HanaGold Cung Cấp',
    solution_item_1_title: 'Quyền mua cổ phiếu ưu đãi',
    solution_item_1_description:
      'Khách hàng ưu tiên có quyền đăng ký mua cổ phiếu của HanaGold với các điều kiện ưu đãi, đầu tư vào sự phát triển của công ty.',
    solution_item_3_title: 'Quyền lợi tài chính ưu đãi',
    solution_item_3_description:
      'Trải nghiệm những ưu đãi đặc biệt về các khoản phí và dịch vụ tài chính, giúp bạn tiết kiệm và tối ưu hóa các giao dịch tài chính của mình.',
    solution_item_2_title: 'Đặc quyền sở hữu thẻ Vàng Priority',
    solution_item_2_description:
      'Được cấp thẻ Vàng Priority đặc biệt, cung cấp những quyền lợi và ưu đãi vượt trội, khẳng định đẳng cấp và vị thế của khách hàng.',
    solution_item_4_title: 'Được chăm sóc và tư vấn đặc biệt',
    solution_item_4_description:
      'Khách hàng sẽ được chăm sóc và tư vấn bởi đội ngũ HanaGold với hotline 247 riêng biệt nhằm đáp ứng các nhu cầu cần thiết của khách hàng.',
    question_title: 'Câu Hỏi Thường Gặp',
    question_item_1_title: 'HanaGold Priority là gì?',
    question_item_1_description:
      'HanaGold Priority là dịch vụ dành riêng cho các khách hàng ưu tiên của HanaGold, mang lại nhiều lợi ích và ưu đãi đặc biệt trong quá trình sử dụng sản phẩm và dịch vụ của HanaGold.',
    question_item_2_title: 'Ai có thể trở thành khách hàng HanaGold Priority?',
    question_item_2_description:
      'Khách hàng đạt điều kiện tích lũy mua sắm và sử dụng dịch vụ theo tiêu chí của HanaGold sẽ được mời tham gia chương trình HanaGold Priority',
    question_item_3_title: 'Lợi ích của HanaGold Priority là gì?',
    question_item_3_description:
      'Ưu tiên phục vụ và chăm sóc khách hàng; nhận các chương trình khuyến mãi và ưu đãi đặc biệt; tham gia các sự kiện độc quyền do HanaGold tổ chức',
    question_item_4_title: 'Điều kiện để đăng ký HanaGold Priority là gì?',
    question_item_4_description:
      'Khách hàng cần đạt các tiêu chí về tích lũy mua sắm và sử dụng dịch vụ của HanaGold theo từng giai đoạn nhất định để đủ điều kiện đăng ký HanaGold Priority.',
    question_item_5_title: 'Làm sao để đăng ký trở thành HanaGold Priority?',
    question_item_5_description:
      'Bạn có thể đăng ký tư vấn tại website hoặc đăng ký trực tiếp trên ứng dụng HanaGold. Tại trang chủ, chọn “Khác”. Sau đó chọn “Priority". Bạn có thể nâng cấp bất kỳ cấp bậc nào.',
    question_item_6_title:
      'Khi trở thành khách hàng ưu tiên, tôi có cần trả phí không?',
    question_item_6_description:
      'HanaGold Priority là dịch vụ dành riêng cho các khách hàng ưu tiên của HanaGold, mang lại nhiều lợi ích và ưu đãi đặc biệt trong quá trình sử dụng sản phẩm và dịch vụ của HanaGold.',
    question_item_7_title:
      'Tôi có thể chia sẻ lợi ích của HanaGold Priority với người khác không?',
    question_item_7_description:
      'Các lợi ích của HanaGold Priority chỉ áp dụng cho chủ tài khoản đã được xác nhận là khách hàng ưu tiên, và không thể chuyển nhượng cho người khác.',
    question_item_8_title: 'Thời gian hiệu lực HanaGold Priority là bao lâu?',
    question_item_8_description:
      'Thời gian hiệu lực của HanaGold Priority là vô hạn. Khách hàng chỉ cần thỏa mãn điều kiện duy trì theo chính sách.',
    question_item_9_title:
      'Nếu có vấn đề hoặc thắc mắc về HanaGold Priority, tôi nên liên hệ với ai?',
    question_item_9_description:
      'HanaGold Priority có hotline 247 và email đặc quyền dành cho khách hàng ưu tiên.',
    question_item_10_title: 'Tại sao tôi nên tham gia HanaGold Priority?',
    question_item_10_description:
      'Tham gia HanaGold Priority mang lại nhiều lợi ích đặc biệt như ưu tiên phục vụ, các chương trình khuyến mãi và ưu đãi độc quyền, cũng như cơ hội tham gia các sự kiện đặc biệt của HanaGold.',
    form_title: 'Khách Hàng Ưu Tiên',
    form_subtitle: 'Đăng ký tư vấn',
    form_name: 'Họ và tên',
    form_email: 'Địa chỉ email',
    form_phone: 'Số điện thoại',
    form_work: 'Công việc hiện tại',
    form_btn: 'Đăng ký',
    sucess_form: 'Đăng ký thành công',
    level: 'CẤP BẬC',
    invalid_email: 'Email không đúng',
    invalid_phone: 'Số điện thoại không đúng',
    required: 'không được để trống'
  },

  business_solutions: {
    title: 'Giải pháp doanh nghiệp',
    banner_title_1: 'Giải Pháp Tặng Thưởng',
    banner_title_2: 'Đa Dạng Và Linh Hoạt Cho Các Doanh Nghiệp',
    banner_description: 'Đối với các doanh nghiệp, việc xây dựng mối quan hệ lâu dài với khách hàng và tạo động lực cho nhân viên là hai yếu tố then chốt để phát triển bền vững.',
    about_title: 'Về Chúng Tôi',
    about_description_1: 'Công ty CP Vàng Bạc Đá Quý HanaGold, thành lập năm 2020, là doanh nghiệp khởi nghiệp đổi mới trong lĩnh vực vàng bạc đá quý.',
    about_description_2: 'Chúng tôi ứng dụng công nghệ 4.0 vào kinh doanh vàng, tạo bước đột phá trong ngành. HanaGold phát triển mô hình cải tiến, đảm bảo giá trị tài sản và mang lại sự an tâm cho khách hàng.',
    about_description_3: 'Công ty cổ phần Vàng Bạc Đá Quý HanaGold thành lập vào năm 2020, là doanh nghiệp khởi nghiệp đổi mới sáng tạo trong lĩnh vực vàng bạc đá quý. Chúng tôi ứng dụng công nghệ 4.0 trong hoạt động kinh doanh vàng (tiệm vàng công nghệ 4.0).',
    about_description_4: 'Tiềm năng trong thời đại công nghệ 4.0 cùng với nhu cầu sử dụng và đầu tư thị trường vàng, HanaGold nhanh chóng chớp lấy thời cơ phát triển ngành vàng tại Việt Nam theo mô hình cải tiến hơn so với truyền thống, tạo bước đột phá cho nền kinh doanh vàng tại Việt Nam.',
    about_description_5: 'Nhiệm vụ của chúng tôi là đảm bảo giá trị tài sản cho bạn và đem lại sự an tâm khi gắn kết.',
    dedicated_solution_1: 'Giải Pháp Dành Riêng Cho',
    dedicated_solution_2: 'Doanh Nghiệp',
    hanagold_reward: {
      description_1: 'HanaGold Reward là giải pháp trao thưởng nóng, thưởng Tết,... hoàn hảo để thể hiện sự ghi nhận, tri ân và giữ chân hiền tài.',
      description_2: 'Tùy theo từng năng lực, cấp bậc và kết quả công việc mà doanh nghiệp sẽ linh hoạt lựa chọn thưởng nhiều hay ít.'
    },
    hanagold_loyalty: {
      description_1: 'HanaGold Loyalty là giải pháp tri ân khách hàng, sử dụng trong các chiến dịch truyền thông, từ đó xây dựng và tạo dựng giá trị với khách hàng trung thành.',
      description_2: 'Quà tặng vàng không chỉ mang giá trị vật chất cao, mà còn thể hiện sự sang trọng và tinh tế.'
    },
    survey_report: {
      title_1: 'Báo Cáo Khảo Sát',
      title_2: 'các Công Ty Thưởng Bằng Tiền, Vàng',
      description: 'Theo Bucketlist Rewards (2024)',
      sastify: {
        sastify_1: 'Hài lòng',
        sastify_2: 'Tăng năng suất',
        sastify_3: 'Tăng năng suất nhóm'
      },
      unsastify: {
        unsastify_1: 'Không hài lòng',
        unsastify_2: 'Không năng suất',
        unsastify_3: 'Không tăng năng suất'
      },
      description_1: 'Mức độ hài lòng của nhân viên khi được thưởng bằng hình thức tiền, vàng',
      description_2: 'Mức độ tăng năng suất của nhân viên khi được thưởng bằng hình thức tiền, vàng',
      description_3: 'Mức độ tăng năng suất của nhóm khi được thưởng bằng hình thức tiền, vàng',
    },
    survey_expense: {
      title_1: 'Khảo Sát Chi Phí',
      title_2: 'Dành Riêng Cho Khoản Thưởng và Chương Trình Marketing',
      reward: 'Chi thưởng',
      expenses: 'Chi phí Marketing',
      b2b_company: 'Công ty B2B',
      b2c_company: 'Công ty B2C',
      salary: 'Mức lương hàng năm'
    },
    benefit_solution: {
      title: 'Ưu Điểm Giải Pháp Doanh Nghiệp Của HanaGold',
      description_1: 'Giảm chi phí đầu tư quà tặng, thời gian, công sức, quy trình',
      description_2: 'Tích trữ, sinh lời từ quỹ phần thưởng, quà tặng',
      description_3: 'Lưu trữ thông tin chi tiết quà tặng',
      description_4: 'Chính sách hoa hồng giới thiệu, mở tài khoản, mua vàng, đầu tư',
      description_5: 'Quà tặng vàng giá trị, sang trọng và tinh tế',
      description_6: 'Quà tặng trực tuyến, nhanh chóng, tiện lợi',
      description_7: 'Đa dạng hình thức quà tặng: Vàng, VNĐ',
    },
    another_feature: {
      title_1: 'Các Tính Năng Khác',
      title_2: 'Doanh Nghiệp Có Thể Ứng Dụng Tại HanaGold',
      item_1: {
        title: 'Cùng hợp tác',
        description: 'Sinh lời nhận thưởng mỗi ngày với kỳ hạn 30, 90 và 365 ngày'
      },
      item_2: {
        title: 'Thưởng mỗi ngày',
        description: 'Nhận thưởng mỗi ngày khi trữ tiền, vàng tại ví chính'
      },
      item_3: {
        title: 'E-Voucher',
        description: 'Tặng vàng cho người thân, bạn bè, gia đình'
      },
      button_view_more: 'Xem thêm'
    },
    guide_use: {
      title: 'Hướng dẫn sử dụng',
      description: 'Xem chi tiết tại mục “Hướng dẫn”',
      button_guide: 'Hướng dẫn'
    }
  },

  course: {
    banner_title_1: 'Chương Trình Đào Tạo',
    banner_title_2: 'Miễn Phí: Đầu Tư Tích Lũy Vàng 4.0 HanaGold',
    resgister_now: 'Đăng ký ngay',
    policy: 'Chính sách',
    title_section_2: 'Có Phải Bạn Đang Gặp Vấn Đề Về tích lũy tài sản?',
    description_1_section_2: 'Khi đối diện với khó khăn việc tích lũy vàng như một thói quen sẽ giúp chúng ta vững chắc về tài chính.',
    description_2_section_2: 'HanaGold mong muốn chia sẻ kiến thức về phương pháp tích vàng góp phần phát triển cộng đồng giúp khách hàng hướng đến sự thịnh vượng bền vững.',
    item_1_section_2: 'Bạn rất thích vàng nhưng chưa biết tìm hiểu khiến bạn chưa an tâm?',
    item_2_section_2: 'Bạn có thu nhập 1 tháng chỉ đủ mức chi tiêu - không đủ để tích luỹ mua vàng 1 chỉ?',
    item_3_section_2: 'Bạn mong muốn hướng đến bền vững tài chính với thói quen tích luỹ vàng?',
    item_4_section_2: 'Bạn có thu nhập cao nhưng bạn chưa biết cách tích lũy và gia tăng tài chính hiện có?',
    title_bot_section_2: 'Vì sao chúng ta mãi chưa giàu có thịnh vượng, mang đến cảm giác bất an trong cuộc sống.',
    description_bot_section_2: 'Làm Chủ Đầu Tư Vàng - Làm Chủ Cuộc Sống Đủ Đầy',
    title_1_section_3: '4 Lí Do',
    title_2_section_3: 'Bạn Nên Đầu Tư và Tích Lũy Sớm',
    description_1_section_3_part_1: 'Chúng ta không thể làm việc suốt đời, cần có',
    description_1_section_3_part_2: 'thời gian nghỉ ngơi',
    description_2_section_3_part_1: 'Tích lũy để mua nhà, lập gia đình,',
    description_2_section_3_part_2: 'xây dựng tương lai con cái',
    description_3_section_3_part_1: 'Tích lũy tài chính',
    description_3_section_3_part_2: 'để khởi nghiệp, trở thành người giàu có',
    description_4_section_3_part_1: 'Tích lũy để thực hiện mục tiêu',
    description_4_section_3_part_2: 'tự do tài chính',
    title_section_4: 'Quy Tắc 6 Chiếc Lọ Tài Chính',
    description_1_section_4: 'Nhu cầu thiết yếu',
    description_2_section_4: 'Đầu tư sinh lợi',
    description_3_section_4: 'Quỹ tiết kiệm',
    description_4_section_4: 'Phát triển bản thân',
    description_5_section_4: 'Hưởng thụ cuộc sống',
    description_6_section_4: 'Từ thiện',
    title_1_section_5: 'Chương Trình Đào Tạo',
    title_2_section_5: 'Phù Hợp Với',
    description_1_section_5: 'Những người muốn tích lũy từ bước cơ bản nhất.',
    description_2_section_5: 'Thanh thiếu niên trên 18 tuổi bắt đầu học cách tích lũy, đầu tư từ sớm.',
    description_3_section_5: 'Người quan tâm đến phương pháp tích lũy tài chính thông minh.',
    description_4_section_5: 'Người có nhu cầu tìm giải pháp đầu tư với số vốn nhỏ.',
    title_1_section_6: 'Dành Trọn',
    title_2_section_6: '3 Buổi Học',
    title_3_section_6: 'Tích Lũy 4.0 Cùng HanaGold',
    day_1_title: 'Buổi 1: Khởi đầu hành trình đầu tư - tích luỹ vàng 4.0',
    day_1_description_1: 'Giới thiệu về HanaGold và sứ mệnh của chúng tôi.',
    day_1_description_2: 'Tổng quan về thị trường vàng và các yếu tố ảnh hưởng.',
    day_2_title: 'Buổi 2: Quản lý và Tích lũy vàng hiệu quả',
    day_2_description_1: 'Hướng dẫn sử dụng ứng dụng HanaGold.',
    day_2_description_2: 'Chiến lược tích lũy vàng và lợi ích khi sử dụng HanaGold.',
    day_3_title: 'Buổi 3: Thực chiến & phương pháp gia tăng thu nhập với HanaGold',
    day_3_description_1: 'Thực hành toàn diện từ quản lý, tích lũy đến đầu tư vàng.',
    day_3_description_2: 'Kiểm tra cuối khóa và trao chứng nhận.',
    title_1_section_7_pc: 'Giá Trị Nhận Được Khi Tham Gia',
    title_2_section_7_pc: 'Chương Trình Đào Tạo',
    title_1_section_7_mb: 'Giá Trị Nhận Được',
    title_2_section_7_mb: 'Khi Tham',
    title_3_section_7_mb: 'Gia Chương Trình Đào Tạo',
    description_1_section_7: 'Kiến thức và kỹ năng phát triển nhìn nhận thị trường đặc biệt trong ngành đầu tư vàng',
    description_2_section_7: 'Cơ hội trở thành đại sứ chính thức cùng HanaGold cùng song hành phát triển',
    description_3_section_7: 'Xây dựng tương lai tài chính ổn định tránh mất thời gian - công sức - tiền bạc cá nhân',
    description_4_section_7: 'Tiếp cận cộng đồng công nghệ học tập phát triển tích lũy cá nhân dài hạn.',
    description_5_section_7: 'Cơ hội gia tăng thu nhập thụ động ngay trên ứng dụng HanaGold ít nhất 2.000.000/tháng',
    description_6_section_7: 'Hỗ trợ xây dựng nhân hiệu cá nhân - nếu là đại sứ được đánh giá tham dự tích cực và hoạt động xuất sắc.',
    title_section_9: 'Những Thắc Mắc Sẽ Được Giải Đáp',
    description_1_section_9: 'Làm sao để có thể giới thiệu được đến bạn bè?',
    description_2_section_9: 'Làm sao có phương pháp đầu tư - tích lũy tài chính lâu dài?',
    description_3_section_9: 'Bạn đã bao giờ hình dung việc mua vàng dễ dàng chỉ từ 100.000 VNĐ?',
    description_4_section_9: 'Bạn hình dung mô hình tích lũy tài chính kèm theo giới thiệu bạn bè gia tăng dòng tiền thụ động?',
    note_section_9: 'Và rất nhiều câu hỏi quan tâm chúng tôi tháo gỡ trong chương trình diễn ra.',
    title_1_section_10: 'Đăng ký tham dự chương tình đào tạo',
    title_2_section_10: 'Đầu Tư & Tích Lũy Vàng',
    fullname: 'Họ và tên',
    email: 'Địa chỉ email',
    phone: 'Số điện thoại',
    ref: 'Người giới thiệu bạn',
    reason: 'Vì sao bạn muốn tham gia khóa học?',
    register: 'Đăng ký',
  }
};
